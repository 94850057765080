// 手机号验证
export const validMobileFormat = function (mobile) {
  // var numberRegStr = /^\d{11}$/
  // var regNum = new RegExp(numberRegStr)
  // if (regNum.test(mobile)) {
  //   var telRegStr = /^(13|14|18|15|17|16|19)\d{9}$/
  //   var reg = new RegExp(telRegStr)
  //   return reg.test(mobile)
  // } else {
  //   return false
  // }
  return mobile
}

export const isEmpty = function (val) {
  // null or undefined
  if (val == null) return true

  if (typeof val === 'boolean') return false

  if (typeof val === 'number') return !val

  if (val instanceof Error) return val.message === ''

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length
    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !val.size
    }
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length
    }
    default:
      break
  }
  return false
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export const looseEqual = function (a, b) {
  const isObjectA = isObject(a)
  const isObjectB = isObject(b)
  if (isObjectA && isObjectB) {
    return JSON.stringify(a) === JSON.stringify(b)
  }
  if (!isObjectA && !isObjectB) {
    return String(a) === String(b)
  }
  return false
}

export const arrayEquals = function (arrayA, arrayB) {
  arrayA = arrayA || []
  arrayB = arrayB || []

  if (arrayA.length !== arrayB.length) {
    return false
  }

  for (let i = 0; i < arrayA.length; i++) {
    if (!looseEqual(arrayA[i], arrayB[i])) {
      return false
    }
  }

  return true
}

export const isEqual = function (value1, value2) {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return arrayEquals(value1, value2)
  }
  return looseEqual(value1, value2)
}

// coerce truthy value to array
export const coerceTruthyValueToArray = function (val) {
  if (Array.isArray(val)) {
    return val
  }
  if (val) {
    return [val]
  }
  return []
}

export const valueEquals = (a, b) => {
  // see: https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
  if (a === b) return true
  if (!(a instanceof Array)) return false
  if (!(b instanceof Array)) return false
  if (a.length !== b.length) return false
  for (let i = 0; i !== a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

//  去除特殊字符
export function stripscript(s) {
  // eslint-disable-next-line prefer-regex-literals
  const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？%]")
  let rs = ''
  for (let i = 0; i < s.length; i++) {
    rs += s.substr(i, 1).replace(pattern, '')
  }
  return rs
}

// 时间戳转化
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time == 'object') {
    date = time
  } else {
    if (`${time}`.length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = `0${value}`
    }
    return value || 0
  })
  return timeStr
}

/*
 * 获取当前n天前/后时间
 * @param {Number}  n +：后n天 -：前n天
 */
export function getDay(n = 0) {
  // 获取 n 天前/后时间戳
  const today = Date.now() + 1000 * 60 * 60 * 24 * n
  return parseTime(today, '{y}-{m}-{d}')
}

// 数字千位数格式化
export function toThousands(number) {
  let num = (number || 0).toString()
  let result = ''
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return result
}

export function debounce(method, delay) {
  let timer = null
  let context
  return function (...args) {
    const that = this
    context = that
    clearTimeout(timer)
    timer = setTimeout(function () {
      method.apply(context, args)
    }, delay)
  }
}
// 格式化时间
export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()
  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  }
  if (diff < 3600) {
    return `${Math.ceil(diff / 60)}分钟前`
  }
  if (diff < 3600 * 24) {
    return `${Math.ceil(diff / 3600)}小时前`
  }
  if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  }
  return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`
}

export function delay(timeout) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout)
  })
}

export function getTheCurrentTime() {
  const date = new Date()
  const year = date.getFullYear() // 年 ,从 Date 对象以四位数字返回年份
  let month = date.getMonth() + 1 // 月 ,从 Date 对象返回月份 (0 ~ 11) ,date.getMonth()比实际月份少 1 个月
  let day = date.getDate() // 日 ,从 Date 对象返回一个月中的某一天 (1 ~ 31)
  let hours = date.getHours() // 小时 ,返回 Date 对象的小时 (0 ~ 23)
  let minutes = date.getMinutes() // 分钟 ,返回 Date 对象的分钟 (0 ~ 59)
  let seconds = date.getSeconds() // 秒 ,返回 Date 对象的秒数 (0 ~ 59)

  // 修改月份格式
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }

  // 修改日期格式
  if (day >= 0 && day <= 9) {
    day = `0${day}`
  }

  // 修改小时格式
  if (hours >= 0 && hours <= 9) {
    hours = `0${hours}`
  }

  // 修改分钟格式
  if (minutes >= 0 && minutes <= 9) {
    minutes = `0${minutes}`
  }

  // 修改秒格式
  if (seconds >= 0 && seconds <= 9) {
    seconds = `0${seconds}`
  }
  // 获取当前系统时间  格式(yyyy-mm-dd hh:mm:ss)
  let currentFormatDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return currentFormatDate
}

// 数组扁平化
export function arrOfOneDimension(val) {
  let newArr = [] // 用于存放转换后的数组
  function arrTool(arr) {
    for (let key of arr) {
      if (Array.isArray(key)) {
        arrTool(key) // 如果还是数组继续递归调用
      } else {
        newArr.push(key)
      }
    }
    return newArr
  }
  return arrTool(val)
}

/**
 * 下载Excel
 * @param {string} url Excel下载地址
 */
export function downloadExcel(url) {
  const bodyEle = document.body
  const formEle = document.createElement('form')
  formEle.style.width = 0
  formEle.style.height = 0
  formEle.action = url
  formEle.method = 'GET'
  bodyEle.appendChild(formEle)
  formEle.submit()
  bodyEle.removeChild(formEle)
}

// 处理async await
export function awaitTo(p) {
  return p.then(res => [null, res]).catch(err => [err, {}])
}

// 生成雪花id
export function generateSnowflakeID() {
  const timestamp = new Date().getTime()
  const workerID = Math.floor(Math.random() * 100000)
  // 将时间戳、工作机器 ID 组合在一起生成雪花 ID
  return `${timestamp}${workerID}`
}

// 复制文本
export function copyText(text) {
  let textareaC = document.createElement('textarea')
  textareaC.setAttribute('readonly', 'readonly') // 设置只读属性防止手机上弹出软键盘
  textareaC.value = text
  document.body.appendChild(textareaC) // 将textarea添加为body子元素
  textareaC.select()
  let res = document.execCommand('copy')
  document.body.removeChild(textareaC) // 移除DOM元素
  return res
}

/**
 * 获取数组对象中的label值
 * 例如传入参数1： 1,2  参数2： [{label:'上海',value:1},{label:'北京',value:2},{label:'广州',value:3}]
 * return: 上海,北京
 */
export function getLabelByValue(value, options) {
  if (!value || !options) return ''
  const arr = value.split(',')
  const res = []
  options.forEach(item => {
    if (arr.includes(item.value.toString())) {
      res.push(item.label)
    }
  })
  return res.join(',')
}

// 仅支持中文
export function validateName(value) {
  return /^[\u4e00-\u9fa5]+$/.test(value)
}
