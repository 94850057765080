<template>
  <div class="console-header" :style="`height: ${headerHeight}`">
    <div
      ref="headerWrapper"
      class="console-header-wrapper"
      :style="`width: calc(100% - ${styleMenuWidth});height: ${headerHeight};top:${showTips ? '60px' : '0'}`"
    >
      <div class="page-header">
        <span class="page-header-heading-title">{{ curRouteMeta.title }}</span>
        <span class="page-header-heading-extra">
          <div class="header-right" :style="`height: ${headerHeight}`">
            <div class="space-item">
              <HeaderAccount class="header-item" />
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { EventBus } from '@/utils/eventBus'

import HeaderAccount from './HeaderAccount.vue'

export default {
  name: 'AdminHeader',
  components: { HeaderAccount },
  props: {
    showTips: {
      type: Boolean,
      default: false,
    },
    styleMenuWidth: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowNotice: 0,
      noLogin: global.noLogin || false,
    }
  },
  computed: {
    ...mapGetters(['store']),
    currentChildRoute() {
      return this.$store.getters.currentChildRoute
    },
    curRouteMeta() {
      return {
        title: this.$route.meta.title || this.currentChildRoute.title,
        subTitle: this.$route.meta.subTitle,
      }
    },
    headerHeight() {
      return `${68}px`
    },
  },
  beforeCreate() {
    // 监听自定义事件方便 三方库
    const handle = () => {
      this.showDrawer()
    }
    window.addEventListener('qmCustomOpenTaskCenter', handle)
    this.$once('hook:destroyed', () => window.removeEventListener('qmCustomOpenTaskCenter', handle))
  },
  mounted() {
    EventBus.$on('showDrawer', this.showDrawer)
  },
  destroyed() {
    EventBus.$off('showDrawer')
  },
  methods: {
    getHeaderHeight() {
      this.$nextTick(() => {
        const headerWrapper = this.$refs.headerWrapper
        return headerWrapper.clientHeight
      })
    },
    // 切换路由
    changeMenu(path) {
      this.$router.push(path)
    },
    // 显示下载中心
    showDrawer() {
      this.$refs.downloadCenter.showDrawer()
    },
  },
}
</script>

<style lang="scss" scoped>
.page-header {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 16px 24px;
  color: var(--color, #333333) !important;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
.page-header-heading-title {
  display: block;
  float: left;
  margin-bottom: 0;
  padding-right: 12px;
  color: var(--color, #333333);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.page-header-heading-sub-title {
  float: left;
  margin: 5px 0;
  margin-right: 12px;
  color: var(--color-1, #606266) !important;
  font-size: 14px;
  line-height: 22px;
}
.console-header {
  width: 100%;
  padding: 0;
  line-height: 1;
  background-color: var(--bg-1, #ffffff);
  // box-shadow: 0px 0px 10px 0px rgba(12, 4, 7, 0.1);
  .console-header-wrapper {
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: var(--bg-1, #ffffff);
    box-shadow: 0px 0px 10px 0px rgba(12, 4, 7, 0.1);
    .header-footer {
      margin-top: -10px;
    }
    .header-right {
      position: absolute;
      top: 0;
      right: 20px;
      display: inline-flex;
      align-items: center;
      background-color: var(--bg-1, #ffffff);
      .header-item {
        cursor: pointer;
        &.header-download {
          color: #fe9e41;
          font-size: 22px;
        }
      }
      .header-btn {
        border-radius: 6px;
      }
    }
    .space-item {
      display: flex;
      align-items: center;
      height: 35px;
      margin-right: 15px;
    }
  }
  .search-service-input {
    ::v-deep .el-input__inner {
      width: 200px;
      height: 34px;
      color: var(--color-1, #666);
      font-size: 14px;
      background: var(--bg-1, #ffffff);
      border: 1px solid var(--line-1, #dcdcdc);
      border-radius: 17px;
    }
    ::v-deep .el-input__icon {
      height: 36px;
      line-height: 36px;
    }
  }
}
.header-user {
  margin-left: 6px;
}
.download-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 34px;
  overflow: hidden;
  color: var(--color-2, #666666);
  font-size: 14px;
  background-color: var(--bg-1, #ffffff);
  border: 1px solid #d9d9d9;
  border-radius: 17px;
  cursor: pointer;
  .icon-download {
    margin-right: 5px;
  }
}

html.dark {
  .download-center {
    color: #cccccc;
    background: #383838;
    border: 1px solid #878787;
  }
}
</style>
