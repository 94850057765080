import { fetchQiNiuToken, fetchQiNiuUrl } from "@/api/common";
import { awaitTo } from "./tool";
const bucket = 'zgoods'

const qiniu = window.qiniu

export const qiniuUrl = 'https://upload.qiniup.com'

export async function getUploadToken (type = 1) {
  const [err, res] = await awaitTo(fetchQiNiuToken({ type }))
  if (err) {
    return null
  }
  return res.data
}

function getQiniuUrl(ids) {
  const data = {
    ids,
    bucket
  }
  fetchQiNiuUrl(data)
}

export function uploadFile (file) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const uploadToken = await getUploadToken()
    if (!uploadToken) {
      reject(new Error('请求七牛token出错,请重试!'))
      return
    }
    // 构建上传的配置对象
    const putExtra = {
      fname: file.name,
    };
    // 调用sdk上传
    const observable = qiniu.upload(file, uploadToken.key, uploadToken.token, putExtra)
    observable.subscribe({
      error(err){
        reject(err)
        console.log(err)
      },
      complete(res){
        getQiniuUrl([res.key]).then(res => {
          resolve(res.data.urls)
        })
      }
    })
  })
}
