import { getLocalItem, setLocalItem } from '@/utils/localStorage'

export default {
  namespaced: true,
  state: {
    user: null,
    permission: null,
  },
  getters: {
    user: state => state.user || getLocalItem('admin.user'),
    permission: state => state.permission || getLocalItem('admin.permissions'),
    roles: state => state.roles || getLocalItem('admin.roles'),
  },
  mutations: {
    setUser(state, val) {
      state.user = val
      setLocalItem('admin.user', val)
    },
    setPermission(state, val) {
      state.permission = val
      setLocalItem('admin.permissions', val)
    },
  },
  actions: {
    logout({ commit }) {
      commit('setUser', null)
      commit('setPermission', null)
    },
  },
}
