<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="用户名" prop="user_id">
        <el-input
          v-model="form.user_id"
          disabled
          placeholder="请输入用户名"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="会员码" prop="vipno">
        <el-input
          v-model="form.vipno"
          disabled
          placeholder="请输入会员码"
          class="item-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户类型" prop="type">
        <el-select v-model="form.type">
          <el-option
            v-for="(v, i) in userTypeList"
            :key="i"
            :label="v.label"
            :value="v.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.type == 2" label="客服ID" prop="kfid">
        <el-input
          v-model="form.kfid"
          placeholder="客服ID"
          class="item-input"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="form.type == 2" label="企微ID" prop="qyid">
        <el-input
          v-model="form.qyid"
          placeholder="企微ID"
          class="item-input"
        ></el-input>
      </el-form-item>
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fetchUpdateUser } from "@/api/account";
import { UserTypeList } from "@/utils/role";

export default {
  name: "EditUser",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        vipno: "", // 会员码
        user_id: "", // 用户ID
        type: "", // 用户类型
      },
      title: "修改用户类型",
      userTypeList: UserTypeList,
      rules: {
        type: [
          { required: true, message: "请选择用户类型", trigger: "change" },
        ],
        kfid: [
          { required: true, message: "请输入客服ID", trigger: "change" },
        ],
        qyid: [
          { required: true, message: "请输入企微ID", trigger: "change" },
        ],
      },
      loading: false,
    };
  },
  methods: {
    // 打开弹窗
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.form = {
          ...this.item,
        };
      });
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    // 提交
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            user_id: this.form.user_id,
            type: this.form.type,
          };
          if (this.form.type == 2) {
            params.kfid = this.form.kfid
            params.qyid = this.form.qyid
          }
          this.loading = true;
          fetchUpdateUser(params)
            .then(() => {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.close();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
