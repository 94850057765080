/* eslint-disable no-unused-vars */
// import { covertArrayToMap } from '@/utils/component.js'

const state = () => ({
  activeComponentIds: [],
  pageComponents: [],
  scale: 1,
  currentSelectedPageType: 'waiting',
  isSubComponent: false,
  isDragging: false,
  isUserComponent: false,
  isKeepRatio: true,
})

const getters = {
  componentsMap(state) {
    // return covertArrayToMap(state.pageComponents)
  },
  activeComponents(state, getters) {
    return state.activeComponentIds.map(id => getters.componentsMap[id])
  },
  lastActiveComponentId(state, getters, rootState, rootGetters) {
    const len = state.activeComponentIds.length
    return len ? state.activeComponentIds[len - 1] : ''
  },
  lastActiveComponent(state, getters, rootState, rootGetters) {
    const len = getters.activeComponents.length
    if (len) {
      const component = getters.activeComponents[len - 1]
      if (component && component.props) {
        return component
      }
    }
    return { props: {} }
  },
}

const mutations = {
  SET_STATE(state, { key, value }) {
    state[key] = value
  },
  SET_COMPONENT_PROP(state, { componentId = this.getters['canvas/lastActiveComponentId'], path, value }) {
    const component = this.getters['canvas/componentsMap'][componentId]
    if (!component) {
      return
    }
    const arr = path.split('.')
    const lastKey = arr.pop()
    let obj = component
    if (obj) {
      arr.forEach(item => (obj = obj[item]))
      obj[lastKey] = value
    }
  },
  REMOVE_COMPONENT(state, { components }) {
    components
      .filter(component => !!component)
      .forEach(component => {
        if (component.tag === 'QmContainer') {
          return
        }
        const parent = this.getters['canvas/componentsMap'][component.parentId]
        const children = parent ? parent.children : state.pageComponents
        children.splice(children.indexOf(component), 1)
      })
    state.activeComponentIds = []
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
