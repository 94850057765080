// 计算方法

// 加
export const numAdd = (num1, num2) => {
  const _num1 = Number(num1)
  const _num2 = Number(num2)
  const m = _pointDigit(_num1, _num2)
  const r = (parseInt((num1 * m + 0.5).toString(), 10) + parseInt((num2 * m + 0.5).toString(), 10)) / m
  return _keepTwoDecimal(r)
}

// 加-保留三位小数
export const numAddForThree = (num1, num2) => {
  const _num1 = Number(num1)
  const _num2 = Number(num2)
  const m = _pointDigit(_num1, _num2)
  const r = (parseInt((num1 * m + 0.5).toString(), 10) + parseInt((num2 * m + 0.5).toString(), 10)) / m
  return Number(r.toFixed(3))
}

// 减
export const numSub = (num1, num2) => {
  const m = _pointDigit(num1, num2)
  const r = (parseInt((num1 * m + 0.5).toString(), 10) - parseInt((num2 * m + 0.5).toString(), 10)) / m
  return _keepTwoDecimal(r)
}

// 乘
export const numMul = (num1, num2) => {
  let m = 0
  const s1 = num1.toString()
  const s2 = num2.toString()
  // m是累加结果
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    //
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    //
  }
  const r = (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m
  return _keepTwoDecimal(r)
}

// 乘-保留三位小数
export const numMulForThree = (num1, num2) => {
  let m = 0
  const s1 = num1.toString()
  const s2 = num2.toString()
  // m是累加结果
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    //
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    //
  }
  const r = (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m
  return Number(r.toFixed(3))
}

// 除
export const numDiv = (num1, num2) => {
  num1 = Number(num1)
  num2 = Number(num2)
  const m = _pointDigit(num1, num2)
  const r = parseInt((num1 * m + 0.5).toString(), 10) / parseInt((num2 * m + 0.5).toString(), 10)
  return _keepTwoDecimal(r)
}

// 获取两个数中最多的小数点位数
const _pointDigit = (num1, num2) => {
  let r1 = 0
  let r2 = 0
  try {
    r1 = num1.toString().split('.')[1].length
  } catch (e) {
    //
  }
  try {
    r2 = num2.toString().split('.')[1].length
  } catch (e) {
    //
  }
  return 10 ** Math.max(r1, r2)
}

// 处理两位小数
// 若超过两位小数，则返回四舍五入后的两位小数
const _keepTwoDecimal = (price) => {
  price = Math.abs(price)
  price *= 100
  const t = Math.floor(price / 100)
  const r = parseInt((price % 100).toFixed(1).split('.')[0], 10)
  const e = Math.floor(r / 10)
  const a = Math.floor(r % 10)
  return Number(r === 0 ? `${t}` : a === 0 ? `${t}.${e}` : `${t}.${e}${a}`)
}
