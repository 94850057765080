export const setLocalItem = (key, val) => {
  try {
    if (val) {
      localStorage.setItem(key, JSON.stringify(val))
    } else {
      localStorage.removeItem(key)
    }
  } catch {
    // do nothing
  }
}

export const getLocalItem = key => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch {
    // do nothing
  }
}
