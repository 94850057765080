import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import { checkShowAside } from '@/common/aside'

import getters from './getters'
import modules from './modules'

Vue.use(Vuex)

export default new Store({
  state: {
    theme: '',
    // 是否显示侧边栏
    showAside: checkShowAside(),
    addParams: {}, // 保存参数
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme
      let target = document.getElementById(process.env.VITE__ROOT_DOM)
      if (theme) {
        target.style.background = '#222222'
      } else {
        target.style.background = ''
      }
    },
    setShowAside(state, val) {
      state.showAside = val
    },
    SET_ADDPARAMS: (state, data) => {
      state.addParams = data
    },
  },
  modules,
  getters,
})
