<template>
  <div class="console-footer">
    <a href="http://beian.miit.gov.cn/" target="_blank">皖ICP备2024061019号-1</a>
  </div>
</template>

<script>

export default {
  name: 'AdminHeader',
  props: {
   
  },
  data() {
    return {}
  },
  methods: {
   
  },
}
</script>

<style lang="scss" scoped>

.console-footer {
  position: fixed;
  width: 100vw;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  user-select: none;
  padding: 4px 0;
  z-index: 1000;
}
</style>
