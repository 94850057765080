
import fetch from '@/utils/fetch'
/**
 * 获取图形验证码
 */
export function fetchCaptcha(params) {
  return fetch({
    url: '/common/captcha/get',
    method: 'GET',
    params,
  })
}

/**
 * 获取qiniu token
 */
export function fetchQiNiuToken(data) {
  return fetch({
    url: '/common/qiniu/token',
    method: 'POST',
    data,
  })
}

/**
 * 获取qiniu url
 */
export function fetchQiNiuUrl(data) {
  return fetch({
    url: '/common/qiniu/url',
    method: 'POST',
    data,
  })
}
