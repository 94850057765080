const getters = {
  menuData: state => state.menu.menuData,
  currentRoute: state => state.menu.currentRoute,
  firstRoute: state => state.menu.firstRoute,
  secondRoute: state => state.menu.secondRoute,
  thirdRoute: state => state.menu.thirdRoute,
  fourthRoute: state => state.menu.fourthRoute,
  selectSecondRoute: state => state.menu.selectSecondRoute,
  selectFirstMenu: state => state.menu.selectFirstMenu, // 当前主路由
  selectSecondMenu: state => state.menu.selectSecondMenu, // 当前子路由
  selectThirdMenu: state => state.menu.selectThirdMenu, // 当前子路由
  selectFourthMenu: state => state.menu.selectFourthMenu, // 当前子路由
  isIgnoreRoute: state => state.menu.isIgnoreRoute, // 是否是忽略路由--用于登录、修改密码等页面
  currentChildRoute: state => state.menu.currentChildRoute,
  errorChildRoute: state => state.menu.errorChildRoute,
  permissions: state => state.menu.permissions,
  theme: state => state.theme,
  showAside: state => state.showAside,
}

export default getters
