<template>
  <div class="login-wrapper-right">
    <div class="login-slogan-content">
      <div class="login-slogan">
        <i class="web-font">Zi-Su</i>
      </div>
      <div class="login-img">
        <img src="@/assets/svg/login-box-bg.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  watch: {},
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'webfont';
  font-display: swap;
  src: url('//at.alicdn.com/t/webfont_ofdyimxx8g.eot'); /* IE9*/
  src:
    url('//at.alicdn.com/t/webfont_ofdyimxx8g.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('//at.alicdn.com/t/webfont_ofdyimxx8g.woff2') format('woff2'),
    url('//at.alicdn.com/t/webfont_ofdyimxx8g.woff') format('woff'),
    /* chrome、firefox */ url('//at.alicdn.com/t/webfont_ofdyimxx8g.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('//at.alicdn.com/t/webfont_ofdyimxx8g.svg#Alibaba-PuHuiTi-Regular') format('svg'); /* iOS 4.1- */
}
.web-font {
  font-size: 22px;
  font-family: 'webfont' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.login-slogan-content {
  position: absolute;
  top: 22%;
  left: 50%;
  text-align: left;
  transform: translateX(-50%);
}
.login-slogan {
  width: 374px;
  margin: 0 auto;
  padding-bottom: 30px;
  color: #fff;
  font-size: 22px;
  line-height: 36px;
  letter-spacing: normal;
}
.login-img {
  width: 380px;
  height: 340px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
