<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="商品名称" prop="name">
        <el-input v-model="form.name" readonly disabled />
      </el-form-item>
      <el-form-item label="商品ID" prop="goods_id">
        <el-input v-model="form.goods_id" readonly disabled />
      </el-form-item>
      <el-form-item label="开始时间" prop="date">
        <el-date-picker
          v-model="form.date"
          type="datetime"
          placeholder="选择开始时间"
          value-format="yyyy/MM/dd HH:mm:ss"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="价格" prop="price">
        <div class="input-wrapper">
          <el-input-number
            v-model="form.price"
            :controls="false"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="9999"
          >
          </el-input-number>
          <span class="append">元</span>
        </div>
      </el-form-item>
      <el-form-item label="组合减免" prop="minus">
        <div class="input-wrapper">
          <el-input-number
            v-model="form.minus"
            :controls="false"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="9999"
          >
          </el-input-number>
          <span class="append">元</span>
        </div>
      </el-form-item>
      <el-form-item label="佣金" prop="bonus">
        <div class="input-wrapper">
          <el-input-number
            v-model="form.bonus"
            :controls="false"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="9999"
          >
          </el-input-number>
          <span class="append">元</span>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="onsale">
        <el-switch
          v-model="form.onsale"
          :active-value="1"
          :inactive-value="0"
          active-text="上架"
          inactive-text="下架"
        ></el-switch>
      </el-form-item>
      <el-form-item label="标签" prop="prompt">
        <el-input
          v-model="form.prompt"
          placeholder="请输入标签"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fetchUpdateOperationInfo } from "@/api/marketing";

export default {
  name: "EditUser",
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "", // 商品名称
        goods_id: "", // 商品ID
        date: "", // 开始时间
        price: "", // 商品价格
        minus: "", // 组合减免
        bonus: "", // 佣金
        onsale: 1, // 状态
        prompt: "", // 标签
        operation_id: "",
      },
      rules: {
        date: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        price: [{ required: true, message: "请输入商品价格", trigger: "blur" }],
        minus: [{ required: true, message: "请输入组合减免", trigger: "blur" }],
      },
      loading: false,
    };
  },
  computed: {
    title() {
      return this.type == "add" ? "添加运营" : "修改运营";
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime();
        },
      };
    },
  },
  methods: {
    // 打开弹窗
    open({ type, goods, operate }) {
      this.type = type;
      if (type == "add") {
        this.form = {
          name: goods.name, // 商品名称
          goods_id: goods.goods_id, // 商品ID
          date: "", // 开始时间
          price: "", // 商品价格
          minus: "", // 组合减免
          bonus: "", // 佣金
          onsale: 1, // 状态
          prompt: "", // 标签
        };
      } else {
        this.form = {
          name: operate.name, // 商品名称
          goods_id: operate.goods_id, // 商品ID
          date: new Date(operate.start_time * 1000).toLocaleString(), // 开始时间
          price: operate.price, // 商品价格
          minus: operate.minus, // 组合减免
          bonus: operate.bonus, // 佣金
          onsale: operate.onsale, // 状态
          prompt: operate.prompt, // 标签
          operation_id: operate.operation_id,
        };
      }
      this.dialogVisible = true;
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    // 提交
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            goods_id: this.form.goods_id,
            edit_type: this.type,
            price: this.form.price,
            minus: this.form.minus,
            bonus: this.form.bonus,
            onsale: this.form.onsale,
            prompt: this.form.prompt,
            start_time: Math.floor(new Date(this.form.date).getTime() / 1000),
          };
          if (this.type == "mod") {
            params.operation_id = this.form.operation_id;
          }
          this.loading = true;
          fetchUpdateOperationInfo(params)
            .then(() => {
              this.loading = false;
              this.$message.success("操作成功");
              if (this.type == "add") {
                this.$router.push({
                  path: "/marketing",
                });
              } else {
                this.$emit("refresh");
              }
              this.close();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-radio-button__inner,
  .el-radio-group {
    font-size: 16px !important;
    line-height: 2;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  //兼容火狐
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .input-wrapper .el-input__inner {
    min-width: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.input-wrapper {
  position: relative;
  display: flex;

  .append {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    padding: 0 20px;
    color: #909399;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #f5f7fa;
    border: 1px solid #d9d9d9;
    border-left: 0;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-sizing: border-box;
    line-height: 30px;
  }
}
</style>
