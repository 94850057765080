<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="用户名" prop="staff_name">
        <el-input v-model="form.staff_name" placeholder="请输入用户名" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item label="角色" prop="staff_type">
        <el-select v-model="form.staff_type">
          <el-option v-for="(v, i) in roleList" :key="i" :label="v.label" :value="v.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input
          v-model="form.mobile"
          placeholder="请输入手机号"
          class="item-input"
          :maxlength="11"
          clearable
        ></el-input>
      </el-form-item>
      <template v-if="type == 'add'">
        <el-form-item label="初始密码" prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入密码"
            class="item-input"
            clearable
          ></el-input>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            placeholder="请输入密码"
            class="item-input"
            clearable
          ></el-input>
        </el-form-item>
      </template>
       
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { fetchAccountManage } from '@/api/account'
import { RoleList } from "@/utils/role"

const validatorPhone = (rule, value, callback) => {
  // 如果为空值，就抛出错误
  if (!value) {
    callback(new Error('请输入手机号'))
  } else {
    // 正则判断手机号格式的格式，正则判断失败抛出错误，否则直接callback()
    let reg = !/^1\d{10}$/.test(value)
    if (reg) {
      callback(new Error('请输入有效的手机号'))
    } else {
      callback()
    }
  }
}

export default {
  name: 'AddUser',
  components: {
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    // 当前类型
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        staff_name: '', // 用户名
        staff_id: '', // 用户ID
        staff_type: '', // 角色
        mobile: '',
        password: '',
        newPassword: '',
      },
      roleList: RoleList,
      rules: {
        staff_name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        staff_type: [{ required: true, message: '请选择角色', trigger: 'change' }],
        mobile: [{ required: true, validator: validatorPhone, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      loading: false,
    }
  },
  computed: {
    title() {
      return this.type == 'add' ? '添加人员' : '修改人员'
    },
  },
  methods: {
    // 打开弹窗
    open() {
      this.dialogVisible = true
      this.$nextTick(() => {
        if (this.type == 'mod') {
          this.form = {
            ...this.item,
          }
        }
      })
    },
    // 关闭
    close() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.form = this.$options.data().form
    },
    // 提交
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let params = {
            staff_name: this.form.staff_name,
            staff_type: this.form.staff_type,
            mobile: this.form.mobile,
            staff_id: this.form.staff_id,
            type: this.type,
          }
          if (this.type == 'add') {
            params.password = this.$md5(this.form.password)
          }
          if (this.type == 'mod' && this.form.newPassword) {
            params.password = this.$md5(this.form.newPassword)
          }
          this.loading = true
          fetchAccountManage(params)
            .then(() => {
              this.loading = false
              this.$message.success('操作成功')
              this.$emit('refresh')
              this.close()
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>
