<template>
  <div class="main-layout-container">
    <div class="console-container">
      <div v-show="showTips" class="browser-tips" :data-version="chromeVersion">
        <span>
          友情提示：您的浏览器{{
            notChrome ? '不是推荐版本' : canUseChrome ? '' : '版本较低'
          }}，访问时可能达不到预期效果（最佳为
          <a target="_blank" href="https://www.google.cn/chrome/">谷歌Google Chrome浏览器</a>，建议更换）
        </span>
        <a class="close-tips" @click="closeBrowserTips">x</a>
      </div>
      <div
        class="console-container-menu"
        :class="{ 'console-menu-active': showMainMenu }"
        :style="{ marginTop: showTips ? '60px' : '', width: styleMenuWidth }"
      >
        <MainMenu
          :menu-data="menuData"
          :show-sub-menu="showSubMenu"
          :show-main-menu="showMainMenu"
          @mainMenuStateChange="menuStateChange"
        ></MainMenu>
        <SubMenu
          v-if="showSubMenu"
          :select-first-menu="selectFirstMenu"
          :fourth-menu="fourthMenu"
          :show-main-menu="showMainMenu"
          :show-sub-menu="showSubMenu"
          @mainMenuStateChange="menuStateChange"
        ></SubMenu>
        <ThirdMenu v-if="fourthMenu.length" :fourth-menu="fourthMenu" :show-main-menu="showMainMenu"></ThirdMenu>
      </div>
      <div class="console-main" :style="{ marginTop: showTips ? '60px' : '', marginLeft: styleMenuWidth }">
        <MainHeader
          :fourth-menu="fourthMenu"
          :show-sub-menu="showSubMenu"
          :show-main-menu="showMainMenu"
          :show-tips="showTips"
          :style-menu-width="styleMenuWidth"
        ></MainHeader>
        <div class="console-main-container" style="display: flex">
          <div class="layout-main" style="flex: 1; min-width: 0">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/eventBus'
import MainHeader from './header/index.vue'
import MainMenu from './menu/mainMenu.vue'
import SubMenu from './menu/subMenu.vue'
import ThirdMenu from './menu/thirdMenu.vue'
import Footer from './footer/index.vue'

export default {
  name: 'DashBoard',
  components: {
    SubMenu,
    MainHeader,
    MainMenu,
    ThirdMenu,
    Footer,
  },
  data() {
    return {
      mainMenuState: true,
      notChrome: false,
      chromeVersion: 0,
      canUseChrome: false,
      shopExpiredInfo: {},
      closePromptRenewalVal: true,
      isShowPromptRenewal: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    // 菜单数据
    menuData() {
      let menuData = JSON.parse(JSON.stringify(this.$store.getters.menuData))
      return this.setMenuData(menuData || [])
    },
    // 显示一级导航
    showMainMenu() {
      return this.mainMenuState || !this.showSubMenu
    },
    // 显示二级导航
    showSubMenu() {
      return this.selectFirstMenu && this.selectFirstMenu.children && this.selectFirstMenu.children.length > 0
    },
    // 选中的一级菜单
    selectFirstMenu() {
      return this.$store.getters.selectFirstMenu
    },
    // 当前四级菜单
    fourthMenu() {
      const selectThirdMenu = this.$store.getters.selectThirdMenu
      if (selectThirdMenu && selectThirdMenu.children) {
        return selectThirdMenu.children.filter(v => !v.redirect)
      }
      return []
    },
    styleMenuWidth() {
      const mainMenuWidth = this.showMainMenu ? 60 : 0
      const subMenuWidth = this.showSubMenu ? 140 : 0
      const thirdMenuWidth = this.fourthMenu.length ? 100 : 0
      const menuWidth = mainMenuWidth + subMenuWidth + thirdMenuWidth
      return `${menuWidth}px`
    },
    showTips() {
      return this.notChrome || (this.notChrome && !this.canUseChrome)
    },
    promptRenewal() {
      const permissions = this.$store.getters.permissions || []
      if (
        this.shopExpiredInfo.fastExpiredNum > 0 &&
        this.isShowPromptRenewal &&
        this.closePromptRenewalVal &&
        permissions.includes('mendianxuqi')
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    'mainMenuState': function () {
      EventBus.$emit('calcTabsLeft', 200, null, true)
    },
    '$route.path': {
      handler(val) {
        if (val === '/home') {
          this.isShowPromptRenewal = true
        } else {
          this.isShowPromptRenewal = false
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const systemInfo = this.getSystemInfo()
    this.notChrome = !systemInfo.isChrome
    this.chromeVersion = systemInfo.chromeVersion
    systemInfo.isChrome && (this.canUseChrome = systemInfo.chromeVersion < 70)
    this.closePromptRenewalVal = JSON.parse(localStorage.getItem('closePromptRenewalScene'))
  },
  created() {},
  destroyed() {
    window.removeEventListener('resize', this.changeFrameHeight)
  },
  methods: {
    // 过滤菜单
    setMenuData(data) {
      // 只保存有子菜单的一级菜单
      // let newData = data.filter(item => {
      //   let isHidden = item.path == '/home'
      //   if (item.path != '/home' && item.children && item.children.length > 0) {
      //     item.children = this.setSecondMenu(item.children)
      //     isHidden = item.children.length > 0
      //   }
      //   return isHidden
      // })
      return data
    },
    setSecondMenu(data) {
      return data
    },
    menuStateChange(state) {
      this.mainMenuState = state
    },
    changeFrameHeight() {
      this.iframe.height = document.documentElement.clientHeight - 120
      const iframeWin = this.iframe.contentWindow
      iframeWin.postMessage('*')
    },
    // 获取设备信息
    getSystemInfo() {
      const ua = navigator.userAgent
      const isWindowsPhone = /(?:Windows Phone)/.test(ua)
      const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
      const isAndroid = /(?:Android)/.test(ua)
      const isFireFox = /(?:Firefox)/.test(ua)
      const isChrome = /(?:Chrome|CriOS)/.test(ua)
      const isTablet =
        /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
      const isPhone = /(?:iPhone)/.test(ua) && !isTablet
      const isPc = !isPhone && !isAndroid && !isSymbian
      // 获取谷歌浏览器版本号
      const arr = ua.split(' ')
      let chromeInfo = ''
      const idx = arr.findIndex(v => /chrome/i.test(v))
      if (idx !== -1) {
        chromeInfo = arr[idx]
      }
      let chromeVersion = 50
      if (chromeInfo) {
        chromeVersion = Number(chromeInfo.split('/')[1].split('.')[0])
      }
      return {
        isTablet,
        isPhone,
        isAndroid,
        isChrome,
        isPc,
        chromeVersion,
      }
    },
    getChromeVersion() {
      let arr = navigator.userAgent.split(' ')
      let chromeVersion = ''
      for (let i = 0; i < arr.length; i++) {
        if (/chrome/i.test(arr[i])) {
          chromeVersion = arr[i]
        }
      }
      if (chromeVersion) {
        return Number(chromeVersion.split('/')[1].split('.')[0])
      }
      return false
    },
    enter() {
      this.mainMenuState = true
    },
    leave() {
      this.mainMenuState = false
    },
    getHeaderHeight() {
      this.$nextTick(() => {
        const headerWrapper = this.$refs.headerWrapper
        return headerWrapper.clientHeight
      })
    },
    getRouteBreadcrumb() {
      const routes = this.$route.matched
      const breadcrumb = []
      if (routes.length >= 1) {
        routes.forEach(item => {
          if (item.path) {
            breadcrumb.push({
              name: item.name,
              path: item.path,
              breadcrumbName: item.name,
            })
          }
        })
      }
      return breadcrumb
    },
    // 关闭浏览器非谷歌提示
    closeBrowserTips() {
      this.notChrome = false
      this.canUseChrome = true
    },
    // 关门门店到期提示
    closePromptRenewal() {
      localStorage.setItem('closePromptRenewalScene', JSON.stringify(0))
      this.closePromptRenewalVal = 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/common';

.main-layout-container {
  height: 100%;
}
.console-container {
  display: flex;
  flex: auto;
  flex-direction: row;
  box-sizing: border-box;
  height: 100%;
  min-height: 0;
  .console-container-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: var(--console-menu-sider, #1273db);
    // margin-left: -70px;
    &.console-menu-active {
      // margin-left: 0;
      .console-main-menu {
        margin-left: 0;
        overflow: auto;
        opacity: 1;
      }
      .console-menu-masking {
        opacity: 0;
      }
    }
    .console-menu-masking {
      position: fixed;
      z-index: 999;
      width: 20px;
      height: 100vh;
      opacity: 1;
    }
    .console-main-menu {
      position: relative;
      z-index: 999;
    }
  }
  .console-menu {
    display: flex;
    height: 100vh;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  }
  .console-main {
    width: 100%;
    overflow-x: auto;
    background-color: var(--bg, #f4f4f4);
    .console-main-container {
      position: relative;
      flex: auto;
      min-height: calc(100% - #{$navbar-height + $main-body-margin});
      margin: 10px;

     .layout-main {
        border-radius: 6px;
        overflow: hidden;
      }
    }
  }
}
.browser-tips {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 60px;
  padding-left: 75px;
  color: #fff;
  font-size: 16px;
  line-height: 60px;
  text-align: center;
  background: url('https://images.qmai.cn/public/img/canyinCenter/img/tipsbgc.jpg') center center no-repeat;
  background-size: cover;
  a {
    color: #ffffff;
    font-size: 18px;
  }
}

.close-tips {
  position: absolute;
  top: 50%;
  right: 50px;
  display: block;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  color: #fff;
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
}
</style>
