<template>
  <div>home</div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {}
  },
}
</script>
