<template>
  <div class="marketing-container">
    <el-form :inline="true" size="small" class="search-box" :model="searchForm">
      <el-form-item label="商品名称/ID">
        <el-input
          v-model="searchForm.word"
          placeholder="请输入商品名称/ID"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }"
      :data="list"
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="start_time"
        label="开始时间"
        width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.start_time | date }}</span>
        </template>
      </el-table-column>
       <el-table-column
        align="center"
        prop="name"
        label="商品"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="goods_id"
        label="商品ID"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="price"
        label="价格"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="组合减免"
        prop="minus"
      ></el-table-column>
      <el-table-column
        align="center"
        label="佣金"
        prop="bonus"
      ></el-table-column>
      <el-table-column
        align="center"
        label="标签"
        prop="prompt"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="onsale"
        label="状态"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.onsale ? "在售" : "下架" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" style="color: #f56c6c" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
        class="pagination"
        background
        layout="total, slot, prev, pager, next"
        :current-page="searchForm.pageNum"
        :page-size="searchForm.pageSize"
        :total="searchForm.total"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <Edit ref="edit" @refresh="getOperationList" />
  </div>
</template>

<script>
import { fetchOperationList, fetchUpdateOperationInfo } from "@/api/marketing";
import Edit from "./components/edit.vue";

export default {
  name: "Marketing",
  data() {
    return {
      isLoading: false,
      list: [],
      searchForm: {
        word: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  filters: {
    date(t) {
      return t ? new Date(t * 1000).toLocaleString() : "-";
    },
  },
  components: {
    Edit,
  },
  created() {
    this.getOperationList();
  },
  methods: {
    search() {
      this.searchForm.pageNum = 1;
      this.getOperationList();
    },
    clearSearch() {
      this.searchForm = {
        word: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      };
      this.getOperationList();
    },
    getOperationList() {
      const data = {
        index: this.searchForm.pageNum - 1,
        size: this.searchForm.pageSize,
      };
      if (this.searchForm.word) {
        data.word = this.searchForm.word;
      }
      fetchOperationList(data)
        .then((res) => {
          const list = res.data.list || [];
          list.forEach((item) => {
            item.createAt = new Date(
              item.create_time * 1000
            ).toLocaleString();
            item.updateAt = new Date(
              item.update_time * 1000
            ).toLocaleString();
          });
          this.searchForm.total = res.data.total;
          this.list = list;
        })
        .catch(() => {});
    },
    // 页码变更
    handlePageChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getOperationList();
    },
    edit(operate) {
      this.$refs.edit.open({ type: "mod", operate });
    },
    del(operate) {
      this.$confirm(`确认要删除该运营信息吗？`, "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            operation_id: operate.operation_id,
            edit_type: "del",
          };

          fetchUpdateOperationInfo(data)
            .then(() => {
              this.$message.success("操作成功");
              this.getOperationList();
            })
            .catch(() => {});
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.marketing-container {
  background: #fff;
  padding: 30px;

  .search-box {
    margin-bottom: 20px;

    .el-form-item {
      margin-right: 20px;
    }

    .cascader-behind-item {
      margin-right: 10px;
    }

    .el-input-number {
      .el-input {
        width: 100%;
      }
    }

    .el-input {
      width: 200px;
    }

    .span-margin {
      margin: 0 5px;
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .pagination ::v-deep {
      margin-top: 20px;
      text-align: right;
      .el-input__inner {
        text-align: center;
      }
    }
  }
}
</style>
