
import fetch from '@/utils/fetch'
/**
 * 获取设置标识符
 */
export function fetchSettingConfigs(params) {
  return fetch({
    url: '/admin/set/configs',
    method: 'GET',
    params,
  })
}

/**
 * 获取指定标识符设置列表
 */
export function fetchSettingList(params) {
  return fetch({
    url: '/admin/set/list',
    method: 'GET',
    params,
  })
}

/**
 * 新增/编辑/删除指定标识符设置
 */
export function fetchSetting(data) {
  return fetch({
    url: '/admin/set/edit',
    method: 'POST',
    data,
  })
}
