
export const Role = {
  finance: '财务',
  admin: '管理员',
  service: '客服',
  operation: '运营'
}

export const RoleList = [
  {
    label: '财务',
    value: 'finance'
  },
  {
    label: '管理员',
    value: 'admin'
  },
  {
    label: '客服',
    value: 'service'
  },
  {
    label: '运营',
    value: 'operation'
  }
]

export const UserType = {
  1: '客户',
  2: '销售',
  3: '合作商',
  4: '平台客服'
}

export const UserTypeList = [
  {
    label: '客户',
    value: 1
  },
  {
    label: '销售',
    value: 2
  },
  {
    label: '合作商',
    value: 3
  },
  {
    label: '平台客服',
    value: 4
  },
]
