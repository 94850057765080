<template>
  <div class="login-container">
    <RegisterRight />
    <div class="login-wrapper-left">
      <div class="login-page">
        <div class="login-main-content">
          <div class="go-back" @click="goHome">
            <img class="go-back-img" src="https://img-shop.qmimg.cn/s1000084/images/2021/06/02/396ecd0deb844301.png" />
            <span class="go-back-word">返回</span>
          </div>
          <div class="login-immediately">
            <div class="login-immediately-title">修改密码</div>
            <div class="login-immediately-content">
              <el-form ref="ruleForm" class="login-form" :model="form" :rules="rules">
                <el-form-item>
                  <el-input
                    v-model="form.password"
                    show-password
                    class="login-item"
                    placeholder="请输入原密码"
                    :max-length="20"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="newPassword">
                  <el-input
                    v-model="form.newPassword"
                    show-password
                    class="login-item"
                    placeholder="请输入新密码"
                    :max-length="20"
                    @focus="phoneInputFocus('ruleForm')"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="confirmPassword">
                  <el-input
                    v-model="form.confirmPassword"
                    show-password
                    class="login-item"
                    placeholder="确认新密码"
                    :max-length="20"
                    @focus="phoneInputFocus('ruleForm')"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="login-account">
                    <el-button
                      class="login-btn"
                      :loading="btnLoading"
                      :class="btnStatus ? 'content-btn' : ''"
                      type="primary"
                      :disabled="!btnStatus"
                      @click="handleSubmit"
                      >确认修改</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import { fetchUpdatePassword } from '@/api/account'
import RegisterRight from '../login/components/registerRight.vue'
import Footer from '@/layouts/footer/index.vue'

const validatorPhone2 = (rule, value, callback) => {
  // 如果为空值，就抛出错误
  if (!value) {
    callback(new Error('请输入新密码'))
  } else {
    callback()
  }
}

const validatorPhone3 = (rule, value, callback) => {
  // 如果为空值，就抛出错误
  if (!value) {
    callback(new Error('请再次输入新密码'))
  } else {
    callback()
  }
}
export default {
  components: {
    RegisterRight,
    Footer,
  },
  data() {
    return {
      showCurrentLoginStatus: 1,
      form: {
        password: '',
        newPassword: '',
        confirmPassword: '',
      },
      btnLoading: false, // 修改密码按钮加载
      rules: {
        newPassword: [{ validator: validatorPhone2, trigger: 'blur' }],
        confirmPassword: [{ validator: validatorPhone3, trigger: 'blur' }],
      },
    }
  },
  computed: {
    btnStatus() {
      const { password, newPassword, confirmPassword } = this.form
      if (password && newPassword && confirmPassword) {
        return true
      }
      return false
    },
  },
  watch: {
    'form.password': function (n) {
      const phone = n && n.trim()
      if (phone) {
        this.form.password = phone.replace(/[\u4E00-\u9FA5]/g, '')
      }
    },
    'form.newPassword': function (n) {
      const phone = n && n.trim()
      if (phone) {
        this.form.newPassword = phone.replace(/[\u4E00-\u9FA5]/g, '')
      }
    },
    'form.confirmPassword': function (n) {
      const phone = n && n.trim()
      if (phone) {
        this.form.confirmPassword = phone.replace(/[\u4E00-\u9FA5]/g, '')
      }
    },
  },
  methods: {
    ...mapMutations('account', ['setUser', 'setPermission']),
    // 提交登录
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const { confirmPassword, newPassword, password } = this.form
          if (confirmPassword != newPassword) {
            this.$message.error('两次密码不一致')
            return
          }
          const data = {
            new_password: this.$md5(newPassword),
            old_password: this.$md5(password),
          }
          this.btnLoading = true
          fetchUpdatePassword(data)
            .then(() => {
              this.btnLoading = false
              this.$message.success('修改成功，请重新登录')
              this.setUser(null)
              this.$store.commit('setMenuData', [])
              window.Cookies.remove('token')
              setTimeout(() => {
                this.$router.replace('/login')
              }, 500)
            })
            .catch(() => {
              this.btnLoading = false
            })
        }
      })
    },
    // 返回上一页
    goHome() {
      const { query } = this.$route
      // 兼容account(1.0)系统的路由携带参数的情况
      if (query.backUrl) {
        window.location.href = decodeURIComponent(query.backUrl)
        return
      }
      this.$router.back()
    },
    // 手机号获取焦点事件
    phoneInputFocus(ruleForm) {
      this.$refs[ruleForm] && this.$refs[ruleForm].clearValidate()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 24px;
}
::v-deep .el-form-item__error {
  font-size: 14px;
}
::v-deep .el-input__inner:focus {
  border-color: none;
  border-right-width: none;
  box-shadow: none;
}
.login-container {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background: #ffffff;
}
.login-page {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
}
.login-main-content {
  position: relative;
  box-sizing: border-box;
  width: 450px;
  height: 560px;
  padding: 20px 35px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
::v-deep .el-input__inner {
  box-sizing: border-box;
  width: 380px;
  height: 46px;
  padding-left: 5px;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 46px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 6px;
  &:not(:first-child) {
    padding-left: 38px;
  }
  &::-webkit-input-placeholder {
    color: #cccccc !important;
  }
}
::v-deep .el-form-item:not(.is-error) .el-input__inner:hover {
  border-color: #89e100;
}
::v-deep .el-form-item:not(.is-error) .el-input__inner:focus {
  border-color: #89e100;
  border-right-width: none;
  box-shadow: none;
}
.login-wrapper-left {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 635px;
  overflow: hidden;
  background: #ffffff;
  .login-header {
    position: absolute;
    top: 30px;
    left: 50px;
    width: 123px;
    height: 36px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .login-page {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.login-wrapper-right {
   position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 520px;
  background-image: url(@/assets/svg/login-bg.svg);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: auto 100%;
}
.login-slogan {
  position: absolute;
  top: 22%;
  left: 50%;
  width: 374px;
  margin: 0 auto;
  color: #8993a9;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  letter-spacing: normal;
  text-align: left;
  transform: translateX(-50%);
}
.login-img {
  position: absolute;
  top: 37%;
  left: 50%;
  width: 520px;
  transform: translateX(-50%);
  img {
    width: 100%;
    height: auto;
  }
}
.go-back {
  display: flex;
  align-items: center;
  height: 16px;
  margin-bottom: 20px;
  line-height: 16px;
  cursor: pointer;
  .go-back-img {
    width: 16px;
    height: 16px;
  }
  .go-back-word {
    margin-left: 2px;
    color: #333333;
    font-weight: 500;
    font-size: 14px;
  }
}
.login-immediately-title {
  color: #000000;
  font-weight: bold;
  font-size: 22px;
}
.login-immediately-content {
  margin-top: 46px;
}
.login-account {
  width: 100%;
  margin-top: 100px;
  ::v-deep .el-button {
    width: 100%;
    height: 46px;
    background: #89e100;
    border: none;
    border-radius: 6px;
    opacity: 0.5;
    transition: opacity 0.3s;
    span {
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .content-btn {
    color: #ffffff;
    opacity: 1;
    transition: opacity 0.3s;
  }
  .el-button:hover {
    opacity: 0.8;
  }
  .content-btn:hover {
    opacity: 0.8;
  }
}
</style>
