<template>
  <div class="user-container">
    <div class="operate">
      <div class="btns btns-wrapper">
        <div>
          <el-button type="primary" size="small" @click="addUser"
            >添加用户</el-button
          >
        </div>
      </div>
    </div>
    <el-table
      :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }"
      :data="list"
      style="width: 100%"
    >
      <el-table-column
        align="center"
        label="用户名"
        min-width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.staff_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="role"
        label="角色"
        min-width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        min-width="180"
        prop="createAt"
      ></el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" style="color: #F56C6C;" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加用户 -->
    <AddUser
      ref="addUser"
      :type="type"
      :item="curItem"
      @refresh="getUserList"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchAccountList, fetchAccountManage } from "@/api/account";
import { Role } from "@/utils/role";
import AddUser from "../components/addUser.vue";

export default {
  data() {
    return {
      isLoading: false,
      type: "",
      list: [],
      curItem: null,
    };
  },
  components: {
    AddUser,
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.getUserList();
  },
  methods: {
    addUser() {
      this.type = "add";
      this.$refs.addUser.open();
    },
    getUserList() {
      fetchAccountList().then((res) => {
        const list = res.data.staffs || [];
        list.forEach((item) => {
          item.createAt = new Date(
            item.create_time * 1000
          ).toLocaleString();
          item.role = Role[item.staff_type];
        });
        this.list = list;
      });
    },
    edit(user) {
      this.curItem = user
      this.type = 'mod'
      this.$refs.addUser.open();
    },
    del(user) {
      this.$confirm(
        `确认要删除【${user.staff_name}】吗？`,
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        const data = {
          staff_id: user.staff_id,
          type: 'del'
        }
        fetchAccountManage(data).then(() => {
          this.getUserList()
        })
      })
      .catch(() => {
        return false
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.user-container {
  background: #fff;
  padding: 30px;

  .btns {
    margin-bottom: 20px;
  }
  .btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
