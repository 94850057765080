import VueRouter from 'vue-router'

import Layout from '@/layouts/index.vue'
import { routerList } from '../router/config'
// 应用配置
const appOptions = {
  router: undefined,
  store: undefined,
}

function setAppOptions(options) {
  const { router, store } = options
  appOptions.router = router
  appOptions.store = store
}

function loadRoutes(cb) {
  // 应用配置
  const { router, store } = appOptions
  /* eslint-disable */
  const routes = router.options.routes
  const dynamicRoutes = transferRouter(routerList)
  console.log(dynamicRoutes, 89)
  const dynamicRouter = [
    {
      path: '/',
      name: 'basicLayout',
      redirect: dynamicRoutes[0].path,
      component: Layout,
      children: dynamicRoutes,
    },
  ]
  routes.unshift(...dynamicRouter)

  router.options = { ...router.options, routes: routes }
  router.matcher = new VueRouter({ ...router.options, routes: [] }).matcher
  // 添加动态路由
  router.addRoutes(routes)

  store.commit('setMenuData', dynamicRoutes)
  // eslint-disable-next-line promise/no-callback-in-promise
  cb && cb(routes[0].path)
}

function transferRouter(target) {
  const { store } = appOptions
  const user = store.getters['account/user']
  const role = user && user.staff_type || ''
  // 映射路由数组
  const mapRoutes = routes => {
    const routesMap = {}
    routes.forEach(item => {
      const path = item.attrAlias.url
      const auth = item.auth || []
      if (role && auth && auth.length && !auth.includes(role)) {
        return
      }
      if (path) {
        const name = path.split('/').slice(-3).join('') + item.id
        // 兜底路由-注入, 用于匹配未在主应用内注册的子页面
        const injectLastRoute = () => [
          {
            name: `${name}-injectLastRoute`,
            path: `${path}/*`,
            meta: {
              injectLastRouteFlag: true,
            },
            children: null,
          },
        ]
        const data = {
          name,
          path,
          meta: {
            title: item.name,
            icon: item.attrAlias.icon,
            serviceId: item.serviceId,
            closable: !(path === '/home'),
            deepth: item.path?.split(',').length,
          },
          children: item.items && item.items.length ? mapRoutes(item.items) : injectLastRoute(item),
        }
        if (item.component) {
          data.component = item.component
        } else {
          data.component = () => import('@/views/empty/index.vue')
        }
        routesMap[data.path] = data
      }
    })
    return routesMap
  }
  const tarMap = mapRoutes(target)
  // 转换为 routes 数组
  const parseRoutesMap = routesMap => {
    return Object.values(routesMap).map(item => {
      if (item.children) {
        item.children = parseRoutesMap(item.children)
      } else {
        delete item.children
      }
      return item
    })
  }
  return parseRoutesMap(tarMap)
}

// 监听路由
function watchRoute() {
  const { store, router } = appOptions
  const {
    history: {
      current: { matched, path: currentRoute },
    },
  } = router
  let firstRoute = ''
  let secondRoute = ''
  let thirdRoute = ''
  let fourthRoute = ''
  let selectSecondRoute = '' // 菜单选中的二级路由地址
  if (matched.length === 1) {
    return
  }

  const routeMatches = matched.filter(v => v.path)
  if (routeMatches.length) {
    routeMatches.forEach((item, index) => {
      // 兜底路由-过滤通配符部分
      const path = item.path.replace('/*', '')
      const deepth = ++index
      if (deepth === 1) {
        firstRoute = path
      } else if (deepth === 2) {
        secondRoute = path
        selectSecondRoute = path
      } else if (deepth === 3) {
        thirdRoute = path
        selectSecondRoute = path
      } else if (deepth === 4) {
        fourthRoute = path
      }
    })
  }
  store.commit('setRoute', { currentRoute, firstRoute, secondRoute, thirdRoute, fourthRoute, selectSecondRoute })

  // 处理各级选中的菜单
  const menuData = store.getters.menuData
  let selectFirstMenu = null
  let selectSecondMenu = null
  let selectThirdMenu = null
  let selectFourthMenu = null
  // 一级菜单
  if (menuData && menuData.length) {
    selectFirstMenu = menuData.find(item => item.path === `${firstRoute}`)
  }
  if (selectFirstMenu && selectFirstMenu.children) {
    const secondArr = [
      `/${firstRoute}/${secondRoute}`,
      `${secondRoute}`,
      `/${secondRoute}/${thirdRoute}`,
      `/${secondRoute}/${thirdRoute}/${fourthRoute}`,
    ]
    // 二级菜单
    selectSecondMenu = selectFirstMenu.children.find(v => secondArr.includes(v.path))
  }
  // 三级菜单
  if (selectSecondMenu && selectSecondMenu.children) {
    const thirdArr = [
      `/${secondRoute}/${thirdRoute}`,
      `/${secondRoute}/${thirdRoute}/${fourthRoute}`,
      `${thirdRoute}`,
      `/${thirdRoute}/${fourthRoute}`,
      `${selectSecondRoute}`,
      `${currentRoute}`,
    ]
    selectThirdMenu = selectSecondMenu.children.find(v => thirdArr.includes(v.path))
  }
  // 四级菜单
  if (selectFourthMenu && selectFourthMenu.children) {
    selectFourthMenu = selectFourthMenu.children
  }
  store.commit('setMenu', filterMenu({ selectFirstMenu, selectSecondMenu, selectThirdMenu, selectFourthMenu }))
}

const filterMenu = menus => {
  for (const key in menus) {
    const menu = menus[key]
    if (menu && menu.children && menu.children.length) {
      menus[key].children = filterInjectLastRouteByMenu(menu.children)
      if (menus[key].children.length === 0) menus[key].children = null
    }
  }
  return menus
}

// 兜底路由-过滤菜单
const filterInjectLastRouteByMenu = arr => {
  return arr.filter(v => {
    if (v.children) {
      v.children = filterInjectLastRouteByMenu(v.children)
      if (v.children.length === 0) v.children = null
    }
    return !v.meta.injectLastRouteFlag
  })
}

function loadGuards(guards, options) {
  const { beforeEach, afterEach } = guards
  const { router } = options
  beforeEach.forEach(guard => {
    if (guard && typeof guard === 'function') {
      router.beforeEach((to, from, next) => {
        guard(to, from, next, options)
      })
    }
  })
  afterEach.forEach(guard => {
    if (guard && typeof guard === 'function') {
      router.afterEach((to, from) => guard(to, from, options))
    }
  })
}

export { loadGuards, loadRoutes, setAppOptions, watchRoute }
