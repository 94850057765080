<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="620px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="商品ID" prop="name">
        <el-input
          v-model="form.goods_id"
          disabled
          placeholder="请输入商品ID"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="商品名称" prop="name">
        <el-input
          v-model="form.name"
          disabled
          placeholder="请输入商品名称"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="附加信息" prop="name">
        <el-table
          :header-cell-style="{
            background: 'var(--bg-7, #f5f5f5)',
            color: 'var(--color, #333333)',
          }"
          :data="extra"
          style="width: 100%"
        >
          <el-table-column align="center" label="参数名" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.name"
                placeholder="请输入参数名"
                maxlength="10"
                show-word-limit
              />
            </template>
          </el-table-column>
          <el-table-column align="center" label="参数值" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.value"
                placeholder="请输入参数值"
                maxlength="10"
                show-word-limit
              />
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <template slot="header">
              <el-button type="primary" @click="addExtra">添加</el-button>
            </template>
            <template slot-scope="scope">
              <el-button
                type="text"
                style="color: #f56c6c"
                @click="del(scope.$index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
          <el-empty slot="empty" :image-size="80">
            <div slot="description">
              <span>暂无数据，点此</span>
              <el-button type="text" @click="addExtra">添加</el-button>
            </div>
          </el-empty>
        </el-table>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fetchUpdateGoodsTxtra } from "@/api/goods";
export default {
  name: "EditGoodsExtra",
  components: {},
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "", // 商品名称
        goods_id: "", // 商品ID
      },
      title: "附加信息",
      loading: false,
      extra: [],
    };
  },
  methods: {
    // 打开弹窗
    open(goods) {
      this.dialogVisible = true;
      const extra = goods.extra || [];
      this.extra = extra;
      this.$nextTick(() => {
        this.form = { ...goods };
      });
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    addExtra() {
      this.extra.push({
        name: "",
        value: "",
      });
    },
    del(index) {
      this.extra.splice(index, 1);
    },
    submitForm() {
      const index = this.extra.findIndex((item) => !item.value || !item.name);
      if (index != -1) {
        this.$message.error("参数名/参数值不可为空");
        return;
      }
      const data = {
        goods_id: this.form.goods_id,
        extra: this.extra,
      };
      fetchUpdateGoodsTxtra(data)
        .then(() => {
          this.loading = false;
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.close();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .hide {
  .el-upload {
    display: none;
  }
}
</style>
