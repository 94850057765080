import fetch from '@/utils/fetch'

/**
 * 查询运营信息列表
 */
export function fetchOperationList(params) {
  return fetch({
    url: '/admin/operation/list',
    method: 'GET',
    params,
  })
}

/**
 * 编辑商品运营信息
 */
export function fetchUpdateOperationInfo(data) {
  return fetch({
    url: '/admin/operation/edit',
    method: 'POST',
    data,
  })
}
