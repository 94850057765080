<template>
  <div
    class="console-main-menu"
    :class="{ shadow: !showSubMenu }"
    :style="{ marginLeft: showMainMenu ? '0' : '-60px' }"
    width="60"
    :collapsible="true"
    :trigger="null"
  >
    <div id="mainMenuWrapper" class="main-menu-wrapper">
      <div class="logo dark">
        <router-link to="/">
          <img class="menu-logo" src="@/assets/logo.jpg" />
        </router-link>
      </div>
      <div class="header-menu">
        <a
          v-for="(item, index) in menuData"
          :key="index"
          :ref="item.path"
          class="menu-link"
          :class="{ 'active-class': item.path === (firstRoute || activePath) }"
          :data-path="item.path"
          @click="changeMenu(item)"
        >
          <i :class="['sealicon', item.meta.icon]"></i>
          <span>{{ item.meta.title.substr(0, 2) }}</span>
        </a>
      </div>
    </div>
    <div v-if="showSubMenu && showMainMenu" class="menu-state-icon" :class="{ active: showSubMenu }">
      <i class="sealicon sealzhankaidaohang" @click.stop="closeMainMenu"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftNav',
  props: {
    menuData: {
      type: Array,
      default: () => [],
    },
    showSubMenu: {
      type: Boolean,
      default: false,
    },
    showMainMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rootSubmenuKeys: [],
      openKeys: [],
      activePath: '',
      isShow: true,
    }
  },
  computed: {
    firstRoute() {
      return this.$store.getters.firstRoute
    },
  },
  methods: {
    closeMainMenu() {
      this.isShow = false
      this.$emit('mainMenuStateChange', false)
    },
    changeMenu(data) {
      let path = data.path.replace('/*', '')
      if (data.children && data.children.length) {
        if (data.children[0].children && data.children[0].children.length > 0) {
          path = data.children[0].children[0].path.replace('/*', '')
        } else {
          path = data.children[0].path.replace('/*', '')
        }
      }
      this.activePath = path
      this.$router.push(path)
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.console-main-menu {
  width: 60px;
  background: transparent;
  user-select: none;
  &.shadow {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  .main-menu-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    height: 100vh;
    min-height: 100vh;
    padding-top: 20px;
    padding-bottom: 50px;
    overflow-y: auto;
    background-color: var(--main-menu-wrapper, #137dee);
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .menu-logo {
    width: 40px;
    height: 40px;
    background-color: var(--bg-1, #ffffff);
    border-radius: 6px;
  }
  .header-menu {
    width: 100%;
    margin-top: 20px;
    padding-top: 10px;
    .menu-link {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 15px 0;
      color: #fff;
      cursor: pointer;
      opacity: 0.65;
      transition: all 0.3s;
      .sealicon {
        font-size: 20px;
      }
      &.active-class {
        font-weight: 700;
        opacity: 1;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .menu-state-icon {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    width: 60px;
    height: 50px;
    // transition: all 0.1s;
    // background-color: var(--theme, #137dee);
    &.active {
      background-color: var(--main-menu-wrapper, #137dee);
    }
    .sealicon {
      margin-left: 24px;
      color: #ffffff60;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .remind-dot {
    position: absolute;
    top: 14px;
    right: 10px;
    width: 6px;
    height: 6px;
    background: #ffb400;
    border-radius: 50%;
  }
}
</style>

<style lang="scss" scoped>
html.dark {
  .console-main-menu {
    .header-menu {
      .menu-link.active-class {
        color: var(--theme, #137dee);
      }
    }
  }
}
</style>
