const routeState = {
  state: {
    menuData: [],
    currentRoute: '',
    firstRoute: '',
    secondRoute: '',
    thirdRoute: '',
    fourthRoute: '',
    selectSecondRoute: '',
    // 各级选中的菜单数据
    selectFirstMenu: null,
    selectSecondMenu: null,
    selectThirdMenu: null,
    selectFourthMenu: null,
    isIgnoreRoute: false,
    currentChildRoute: {},
    errorChildRoute: '',
    permissions: [], // 操作权限
  },
  mutations: {
    setMenuData: (state, menuData) => {
      state.menuData = JSON.parse(JSON.stringify(menuData))
    },
    setRoute: (state, data) => {
      const { currentRoute, firstRoute, secondRoute, thirdRoute, fourthRoute, selectSecondRoute } = data
      state.currentRoute = currentRoute
      state.firstRoute = firstRoute
      state.secondRoute = secondRoute
      state.thirdRoute = thirdRoute
      state.fourthRoute = fourthRoute
      state.selectSecondRoute = selectSecondRoute
    },
    setSelectSecondRoute: (state, data) => {
      state.selectSecondRoute = data
    },
    setMenu: (state, data) => {
      const { selectFirstMenu, selectSecondMenu, selectThirdMenu, selectFourthMenu } = data
      state.selectFirstMenu = selectFirstMenu
      state.selectSecondMenu = selectSecondMenu
      state.selectThirdMenu = selectThirdMenu
      state.selectFourthMenu = selectFourthMenu
    },
    setIgnoreRouteState: (state, data) => {
      state.isIgnoreRoute = data
    },
    setCurrentChildRoute: (state, data) => {
      state.currentChildRoute = data
    },
    setErrorChildRoute: (state, data) => {
      state.errorChildRoute = data
    },
    setPermissions: (state, data) => {
      state.permissions = data
    },
  },
  actions: {
  },
}

export default routeState
