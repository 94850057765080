export default {
  namespaced: true,
  state: {
    fixedTabs: false, // 固定页签头，true:固定，false:不固定
  },
  getters: {},
  mutations: {
    setFixedTabs(state, fixedTabs) {
      state.fixedTabs = fixedTabs
    },
  },
}
