<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="标识符" prop="identifier">
        <el-input v-model="form.identifier" readonly disabled />
      </el-form-item>
      <el-form-item label="说明" prop="description">
        <el-input
          v-model="form.description"
          placeholder="请输入说明"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="开始时间" prop="date">
        <el-date-picker
          v-model="form.date"
          type="datetime"
          placeholder="选择开始时间"
          value-format="yyyy/MM/dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="佣金" prop="bonus">
        <div class="input-wrapper">
          <el-input-number
            v-model="form.bonus"
            :controls="false"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="100"
          >
          </el-input-number>
          <span class="append">%</span>
        </div>
      </el-form-item>
      <el-form-item label="周一" prop="monday">
        <div class="input-wrapper">
          <el-input-number
            v-model="form.monday"
            :controls="false"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="100"
          >
          </el-input-number>
          <span class="append">%</span>
        </div>
      </el-form-item>
      <el-form-item label="周二" prop="tuesday">
        <div class="input-wrapper">
          <el-input-number
            v-model="form.tuesday"
            :controls="false"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="100"
          >
          </el-input-number>
          <span class="append">%</span>
        </div>
      </el-form-item>
      <el-form-item label="周三" prop="wednesday">
        <div class="input-wrapper">
          <el-input-number
            v-model="form.wednesday"
            :controls="false"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="100"
          >
          </el-input-number>
          <span class="append">%</span>
        </div>
      </el-form-item>
      <el-form-item label="周四" prop="thursday">
        <div class="input-wrapper">
          <el-input-number
            v-model="form.thursday"
            :controls="false"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="100"
          >
          </el-input-number>
          <span class="append">%</span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fetchSetting } from "@/api/setting";
import { numAdd } from "@/utils/calc";

export default {
  name: "EditSupport",
  props: {
    // 当前类型
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        identifier: "bonus_apportion",
        system_set_id: "",
        date: "",
        bonus: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        description: "",
      },
      rules: {
        date: [{ required: true, message: "请选择开始时间", trigger: "blur" }],
      },
      loading: false,
    };
  },
  computed: {
    title() {
      return this.type == "add" ? "添加分配" : "修改分配";
    },
  },
  methods: {
    // 打开弹窗
    open(rule) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (this.type == "mod") {
          this.form = {
            identifier: "bonus_apportion",
            system_set_id: rule.system_set_id,
            date: new Date(rule.start_time * 1000).toLocaleString(),
            description: rule.description,
            ...rule.value,
          };
        }
      });
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    getAllRate() {
      const { bonus, monday, tuesday, wednesday, thursday } = this.form;
      let arr = [bonus, monday, tuesday, wednesday, thursday];
      let all = 0;
      all = arr.reduce((total, item) => {
        return numAdd(total, item);
      }, all);
      return all;
    },
    // 提交
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const rate = this.getAllRate();
          if (rate > 100) {
            this.$message.error("比例之和不可超过100");
            return;
          }
          let params = {
            description: this.form.description,
            identifier: this.form.identifier,
            start_time: Math.floor(new Date(this.form.date).getTime() / 1000),
            type: this.type,
            value: {
              bonus: this.form.bonus,
              monday: this.form.monday,
              tuesday: this.form.tuesday,
              wednesday: this.form.wednesday,
              thursday: this.form.thursday,
            },
          };
          if (this.type == "mod") {
            params.system_set_id = this.form.system_set_id;
          }
          this.loading = true;
          fetchSetting(params)
            .then(() => {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.close();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-radio-button__inner,
  .el-radio-group {
    font-size: 16px !important;
    line-height: 2;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  //兼容火狐
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .input-wrapper .el-input__inner {
    min-width: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.input-wrapper {
  position: relative;
  display: flex;

  .append {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    padding: 0 20px;
    color: #909399;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #f5f7fa;
    border: 1px solid #d9d9d9;
    border-left: 0;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-sizing: border-box;
    line-height: 30px;
  }
}
</style>
