import { loginIgnore } from '@/router/index'
import { watchRoute } from '@/utils/routerUtil'

const progressStart = (to, from, next) => {
  next()
}

// eslint-disable-next-line max-params
const loginGuard = (to, from, next, options) => {
  const callback = to.query?.callback
  if (callback) {
    window.location.replace(decodeURIComponent(callback).replace('http:', 'https:'))
    return
  }

  const { store } = options
  if (loginIgnore.includes(to)) {
    // 登录、修改密码等页面设置body最小宽度为 'auto' 以自适应居中
    document.getElementsByTagName('body')[0].style.minWidth = 'auto'
    store.commit('setIgnoreRouteState', true)
  } else {
    document.getElementsByTagName('body')[0].style = ''
    store.commit('setIgnoreRouteState', false)
  }
  document.title = `${to.meta.title ? `${to.meta.title} - ` : ''}ZiSu管理平台`
  const token = window.Cookies.get('token')
  if (!loginIgnore.includes(to) && !token) {
    next({ path: '/login' })
  } else {
    next()
  }
}

export default {
  beforeEach: [progressStart, loginGuard],
  afterEach: [watchRoute],
}
