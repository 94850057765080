<template>
  <div id="Select" class="header-user">
    <el-popover
      v-model="hovered"
      trigger="hover"
      width="100"
      placement="bottom-end"
      popper-class="header-account-popover"
    >
      <router-link class="item-brand" to="/updatePassword">
        <i class="sealicon sealxiugai1"></i>
        <span>修改密码</span>
      </router-link>
      <div class="item-brand" @click="logout">
        <i class="sealicon sealtuichu"></i>
        <span>退出登录</span>
      </div>
      <div slot="reference" class="wrapper">
        <span class="item-brand">{{ roleName }}</span>
        <div class="user-name">{{ userName | filterName }}</div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'HeaderAvatar',
  filters: {
    filterName(str) {
      if (str) {
        return str.length > 2 ? str.substring(str.length - 2) : str
      }
      return '用户'
    },
  },
  data() {
    return {
      typeArr: ['管理员', '督导', '门店'],
      popoverVisible: false,
      brandList: [],
      clicked: false,
      hovered: false,
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    userName() {
      return this.user ? this.user.staff_name : '用户'
    },
    roleName () {
      let role = ''
      switch (this.user.staff_type) {
        case 'finance':
          role = '运营'
          break
          case 'service':
          role = '客服'
          break
          case 'admin':
          role = '管理员'
          break
          default:
            break
      }
      return role
    }
  },
  created() {
    console.log(this.user, 89)
  },
  methods: {
    ...mapMutations('account', ['setUser', 'setPermission']),
    handleHoverChange(visible) {
      if (this.hovered && this.clicked) {
        this.clicked = true
      } else {
        this.hovered = visible
      }
    },
    handleClickChange(visible) {
      this.clicked = visible
    },
    logout() {
      this.setUser(null)
      this.$store.commit('setMenuData', [])
      window.Cookies.remove('token')
      this.$router.go(0)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
}
.header-user {
  display: flex;
  align-items: center;
  .user-name {
    width: 35px;
    height: 35px;
    margin: -2px;
    color: #fff;
    font-size: 12px;
    line-height: 35px;
    text-align: center;
    background-color: var(--theme, #137dee);
    border-radius: 50%;
    transform: scale(0.8);
  }
  .user-state {
    padding: 4px 15px;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    background-color: var(--theme, #137dee);
    border-top-right-radius: 5px;
    border-bottom-left-radius: 20px;
  }
}
.item-brand {
  display: block;
  margin-right: 10px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  &:first-child {
    padding-top: 0;
  }
  &:hover {
    color: var(--theme, #137dee);
    .sealicon {
      color: var(--theme, #137dee);
    }
  }
  span {
    padding-left: 5px;
  }
  .sealicon {
    color: var(--color-5, #999999);
    font-size: 17px;
    transition: color 0.3s;
  }
}
</style>

<style lang="scss">
.el-popover.header-account-popover {
  min-width: 120px;
}
</style>
