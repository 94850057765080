<template>
  <div class="login-main-content">
    <div class="login-main">
      <div>
        <p class="login-title">登录</p>
      </div>
      <div class="login-operation">
        <div class="login-form">
          <el-form ref="ruleForm2" :model="form" :rules="rules" @submit.native.prevent>
            <el-form-item prop="mobile">
              <el-input
                ref="account"
                v-model="form.mobile"
                class="mobile-input"
                :max-length="20"
                placeholder="请输入用户名"
                @focus="phoneInputFocus('ruleForm2')"
                @keyup.enter.native="openService2"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                ref="password"
                v-model="form.password"
                show-password
                class="mobile-input"
                placeholder="请输入密码"
                :max-length="20"
                @focus="phoneInputFocus('ruleForm2')"
                @keyup.enter.native="openService3"
              ></el-input>
            </el-form-item>
            <el-form-item prop="captcha">
              <div class="captcha-wrapper">
                <el-input
                  ref="captcha"
                  v-model="form.captcha"
                  class="mobile-input"
                  :max-length="20"
                  placeholder="请输入验证码"
                  @focus="phoneInputFocus('ruleForm2')"
                  @keyup.enter.native="openService4"
                >
                </el-input>
                <div v-html="svg" class="svg" @click="getCode"></div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="login-main-btn2">
          <div class="next-step login-next-step">
            <el-button
              type="primary"
              :loading="btnLoading"
              :class="btnStatus ? 'content-btn' : ''"
              :disabled="!btnStatus"
              @click="openService4"
              >登录</el-button
            >
          </div>
          <!-- <div class="forget-the-password">
            <div class="agreement-of-ming">
              <el-checkbox v-model="agreeProtocol2" @change="agreedChange">
                <span class="haveRead">我已阅读并同意</span>
                <span size="small" type="link" class="look-service" @click.stop.prevent="lookService">服务协议</span
                >和<span size="small" type="link" class="look-service" @click.stop.prevent="lookPrivacyPolicy"
                  >隐私政策</span
                >
              </el-checkbox>
            </div>
            <span class="forget-text" @click="forgetThePassword">忘记密码?</span>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <AgreeToLoginPop ref="agreeToLoginPop" @agreement="agreement"></AgreeToLoginPop> -->
  </div>
</template>

<script>
import {fetchCaptcha} from '@/api/common'

// import AgreeToLoginPop from './agreeToLoginPop.vue'
export default {
  components: {
    // AgreeToLoginPop,
  },
  props: {
    btnLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginType: 2, // 1是验证码登录 2 密码登录
      isShowMsg: false, // 手机号错误提示显示
      isLoginPassword: false, // 登录密码
      captchaPromptVisible: false, // 协议弹窗
      loading: false, // 扫码登录
      form: {
        mobile: '',
        password: '',
        captcha: ''
      },
      svg: '',
      login_url: '', // 飞书登录url
      codeLoading: false, // 手机验证码登录Loading
      codeUrl: '', // 扫码二维码路径
      timer: '', // 定时器
      codeExpired: false, // app扫码已过期
      codeStatus: 0, // 扫码状态
      saoCodeLoading: false, // 扫码loading
      agreeProtocol1: false, // 同意协议
      agreeProtocol2: false, // 同意协议
      rules: {
        mobile: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
    }
  },
  computed: {
    btnStatus() {
      //  账号密码登录 按钮状态
      const { mobile, password, captcha } = this.form
      return mobile && password && captcha
    },
  },
  mounted() {
    this.getCode()
  },
  methods: {
    // 获取验证码
    getCode() {
      fetchCaptcha().then(res => {
        this.captchaId = res.data.captcha_id
        this.svg = res.data.svg
      })
    },
    // 账号密码登录 验证账号信息
    openService2() {
      setTimeout(() => this.$refs.password.focus(), 0)
    },
    // 验证码
    openService3() {
      setTimeout(() => this.$refs.captcha.focus(), 0)
    },
    // 账号密码登录 验证账号信息
    openService4() {
      this.$refs.ruleForm2.validate(valid => {
        if (valid) {
          // if (!this.agreeProtocol2) {
          //   this.$refs.agreeToLoginPop.show()
          //   return
          // }
          const { mobile, password, captcha } = this.form
          if (!mobile) {
            this.$message.error('请输入用户名')
            return false
          }
          if (!password) {
            this.$message.error('请输入密码')
            return false
          }
           if (!captcha) {
            this.$message.error('请输入验证码')
            return false
          }
          this.isLoginPassword = true
          const loginData = {
            name: mobile,
            password: password,
            captchaId: this.captchaId,
            captcha,
          }
          this.$emit('accountPasswordLoginSucceed', loginData)
        }
      })
    },
    // 修改密码登录
    changePassword() {
      this.$router.push('/updatePassword')
    },
    // 查看服务协议
    lookService() {
      const routeUrl = this.$router.resolve({
        path: '/agreement',
      })
      window.open(routeUrl.href, '_blank')
    },
    // input 获取焦点事件
    phoneInputFocus(ruleForm) {
      this.$refs[ruleForm] && this.$refs[ruleForm].clearValidate()
    },
    // 隐私政策
    lookPrivacyPolicy() {
      const routeUrl = this.$router.resolve({
        path: '/privacyPolicy',
      })
      window.open(routeUrl.href, '_blank')
    },
    // 同意勾选服务协议
    agreement() {
      if (this.loginType == 1) {
        this.agreeProtocol1 = true
        this.$refs.agreeToLoginPop.close()
        this.openService()
      } else {
        this.agreeProtocol2 = true
        this.$refs.agreeToLoginPop.close()
        this.openService3()
      }
    },
    // 同意登录
    agreedChange(val) {
      if (this.loginType == 1) {
        this.agreeProtocol1 = val
      } else {
        this.agreeProtocol2 = val
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  background-color: rgba(255, 255, 255, 0.9);
}
::v-deep .el-checkbox {
  color: #606266;
}
::v-deep .el-checkbox__inner {
  background-color: #ffffff;
  border: 1px solid #dcdfe6;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #2a50ec;
  border-color: #2a50ec;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #2a50ec;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(0, 0, 0, 0.65);
}
::v-deep .el-input__inner:focus {
  border-color: #2a50ec;
  border-right-width: none;
  box-shadow: none;
}
::v-deep .el-input__inner:hover {
  border-color: #2a50ec;
}
.login-main-content {
  padding-top: 45px;
  .login-title {
    margin-bottom: 15px;
    padding-bottom: 15px;
    color: #000000;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
  }
  .agreement-of-ming {
    color: #333333;
    font-weight: 500;
    font-size: 14px;
  }
  .service-agreement {
    padding: 0 0;
  }
  .service-agreement:hover {
    color: #677bcb;
  }
  .scan-login-code {
    position: absolute;
    top: 10px;
    left: -87px;
    display: none;
    width: 80px;
    height: 42px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    background: #2a50ec;
    border-radius: 4px;
  }
  .scan-login-code::after {
    position: absolute;
    top: 16px;
    right: -6px;
    display: block;
    width: 0;
    height: 0;
    background: #2a50ec;
    border-color: #2a50ec #2a50ec transparent transparent;
    border-style: solid;
    border-width: 6px;
    -webkit-transform: rotate(45deg) translate(0);
    transform: rotate(45deg) translate(0);
    content: '';
  }
  .corner-mark:hover {
    .scan-login-code {
      display: block;
    }
  }
}
.login-main {
  padding-top: 28px;
}
.login-password {
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  font-family: PingFang SC;
  span {
    position: relative;
    display: inline-block;
    color: #666666;
    font-weight: 500;
    font-size: 14px;
  }
  .select-login-type {
    color: #2a50ec;
  }
  .verification-login {
    cursor: pointer;
    transition: all 0.3s;
  }
  .verification-login:hover {
    color: #2a50ec;
  }
  .letter-login {
    cursor: pointer;
    transition: all 0.3s;
  }
  .password-login {
    margin-left: 29px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .password-login:hover,
  .letter-login:hover {
    color: #2a50ec;
  }
  .select-line {
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 30px;
    height: 4px;
    background: #2a50ec;
    border-radius: 2px;
    transform: translateX(-50%);
  }
}
.login-form {
  margin-top: 30px;
  .mobile-input-error-msg {
    margin-top: 6px;
    color: #ff1313;
    font-weight: 500;
    font-size: 14px;
  }
  ::v-deep .el-input__inner {
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    border-radius: 6px;
    &::-webkit-input-placeholder {
      color: #cccccc !important;
    }
  }
  ::v-deep .el-input__inner:hover {
    border-color: #2a50ec;
  }
}
.next-step {
  width: 100%;
  ::v-deep .el-button {
    width: 380px;
    height: 46px;
    background: #2a50ec;
    border: none;
    border-radius: 6px;
    opacity: 0.5;
    span {
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .content-btn {
    color: #ffffff;
    opacity: 1;
    transition: opacity 0.3s;
  }
  .content-btn:hover {
    opacity: 0.8;
  }
}
.forget-the-password {
  display: flex;
  align-content: center;
  justify-content: space-between;
  height: 44px;
  color: #2a50ec;
  font-weight: 500;
  font-size: 14px;
  line-height: 44px;
  span {
    cursor: pointer;
  }
}
.forget-text:hover {
  color: #677bcb;
}
.login-next-step {
  margin-top: 95px;
}
.corner-mark {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  .corner-mark-img {
    width: 62px;
    height: 62px;
    opacity: 0.7;
    transition: all 0.3s;
  }
  .corner-mark-img:hover {
    opacity: 1;
  }
}

.captcha-wrapper {
  position: relative;

  .svg {
    position: absolute;
    right: 5px;
    top: 2px;
    height: 40px;
    background: #fff;
    cursor: pointer;
  }
}

::v-deep .svg svg {
  height: 100%;
}

.login-main-btn2 {
  position: absolute;
  bottom: 85px;
}
.fly-book-form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  .fly-book-text {
    display: block;
    margin-left: 10px;
    color: #515a6e;
    font-weight: bold;
    font-size: 26px;
  }
}

.agreement-of-ming {
  height: 44px;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
}
.allopatric-prompt-visible {
  width: 550px;
  height: 200px;
  .long-distance-login {
    padding: 0 12px;
  }
  .warm-prompt {
    display: flex;
    justify-content: flex-start;
    color: #333333;
    font-weight: bold;
    font-size: 24px;
    &_hint {
      padding-top: 24px;
      color: #333333;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFang SC;
    }
  }
  .login-message {
    margin-top: 34px;
    color: #333333;
    font-weight: 500;
    font-size: 16px;
  }
  .change-password {
    width: 146px;
    height: 47px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #2a50ec;
    border-radius: 6px;
  }
  .validation-research {
    width: 146px;
    height: 47px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    background: #2a50ec;
    border-radius: 6px;
  }
}

.look-service {
  color: #2a50ec;
  cursor: pointer;
}
.look-service:hover {
  color: #677bcb;
}
.service-agreement {
  padding: 0 0;
}
.service-agreement:hover {
  color: #677bcb;
}
.haveRead {
  user-select: none;
}
</style>
