import fetch from '@/utils/fetch'

/**
 * 账户登录
 */
export function fetchLoginAccount(data) {
  return fetch({
    url: '/admin/account/login',
    method: 'POST',
    data,
  })
}
/**
 * 查询账户信息
 */
export function fetchAccountInfo(params) {
  return fetch({
    url: '/admin/account/info',
    method: 'GET',
    params,
  })
}

/**
 * 修改账户信息
 */
export function fetchUpdateAccountInfo(data) {
  return fetch({
    url: '/admin/account/modify',
    method: 'POST',
    data,
  })
}

/**
 * 修改账户密码
 */
export function fetchUpdatePassword(data) {
  return fetch({
    url: '/admin/account/password',
    method: 'POST',
    data,
  })
}

/**
 * 查询账户列表
 */
export function fetchAccountList(params) {
  return fetch({
    url: '/admin/account/list',
    method: 'GET',
    params,
  })
}

/**
 * 添加/编辑/删除账户
 */
export function fetchAccountManage(data) {
  return fetch({
    url: '/admin/account/manage',
    method: 'POST',
    data,
  })
}

/**
 * 查询用户列表
 */
export function fetchUserList(params) {
  return fetch({
    url: '/admin/user/list',
    method: 'GET',
    params,
  })
}

/**
 * 修改用户类型
 */
export function fetchUpdateUser(data) {
  return fetch({
    url: '/admin/user/edit',
    method: 'POST',
    data,
  })
}
