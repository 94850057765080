<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="标识符" prop="identifier">
        <el-input v-model="form.identifier" readonly disabled />
      </el-form-item>
      <el-form-item label="说明" prop="description">
        <el-input
          v-model="form.description"
          placeholder="请输入说明"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="开始时间" prop="date">
        <el-date-picker
          v-model="form.date"
          type="datetime"
          placeholder="选择开始时间"
          value-format="yyyy/MM/dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="version_url" prop="version_url">
        <el-input
          v-model="form.version_url"
          placeholder="请输入version_url"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fetchSetting } from "@/api/setting";

export default {
  name: "EditSupport",
  props: {
    // 当前类型
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        identifier: "support",
        date: "",
        system_set_id: "",
        description: "",
        version_url: "",
      },
      rules: {
        date: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        version_url: [
          { required: true, message: "请输入version_url", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  computed: {
    title() {
      return this.type == "add" ? "添加页面" : "修改页面";
    },
  },
  methods: {
    // 打开弹窗
    open(page) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (this.type == "mod") {
          this.form = {
            identifier: page.identifier,
            system_set_id: page.system_set_id,
            description: page.description,
            date: new Date(page.start_time * 1000).toLocaleString(),
            ...page.value,
          };
        }
      });
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    // 提交
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            description: this.form.description,
            identifier: this.form.identifier,
            type: this.type,
            start_time: Math.floor(new Date(this.form.date).getTime() / 1000),
            value: {
              version_url: this.form.version_url,
            },
          };
          if (this.type == "mod") {
            params.system_set_id = this.form.system_set_id;
          }
          this.loading = true;
          fetchSetting(params)
            .then(() => {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.close();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
