<template>
  <div class="support">
    <div class="search-box">
      <el-button type="primary" @click="addBonus">添加分配</el-button>
    </div>
    <el-table
      :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }"
      :data="list"
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="table-expand">
            <el-form-item label="佣金">
              <span>{{ props.row.value.bonus }}</span>
            </el-form-item>
            <el-form-item label="周一">
              <span>{{ props.row.value.monday }}</span>
            </el-form-item>
            <el-form-item label="周二">
              <span>{{ props.row.value.tuesday }}</span>
            </el-form-item>
            <el-form-item label="周三">
              <span>{{ props.row.value.wednesday }}</span>
            </el-form-item>
            <el-form-item label="周四">
              <span>{{ props.row.value.thursday }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="设置ID"
        prop="system_set_id"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="说明"
        prop="description"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.description || "-" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="开始时间"
        prop="start_time"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.start_time | date }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="isShowBtn" label="操作" align="center" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" style="color: #f56c6c" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
        class="pagination"
        background
        layout="total, slot, prev, pager, next"
        :current-page="searchForm.pageNum"
        :page-size="searchForm.pageSize"
        :total="searchForm.total"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <edit ref="edit" :type="type" @refresh="getSettingList"> </edit>
  </div>
</template>

<script>
import { fetchSettingList, fetchSetting } from "@/api/setting";
import Edit from "./edit.vue";
export default {
  name: "BonusApportion",
  components: { Edit },
  data() {
    return {
      type: "",
      identifier: "bonus_apportion",
      list: [],
      searchForm: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getSettingList();
  },
  computed: {
    isShowBtn() {
      return !this.list.length || this.list.length > 2 || this.list[0].system_set_id
    }
  },
  filters: {
    date(t) {
      return t ? new Date(t * 1000).toLocaleString() : "-";
    },
  },
  methods: {
    getSettingList() {
      fetchSettingList({
        index: this.searchForm.pageNum - 1,
        size: this.searchForm.pageSize,
        identifier: this.identifier,
      })
        .then((res) => {
          this.searchForm.total = res.data.total || 0;
          this.list = res.data.list || [];
        })
        .catch(() => {
          this.list = [];
        });
    },
    // 页码变更
    handlePageChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getSettingList();
    },
    addBonus() {
      this.type = "add";
      this.$refs.edit.open();
    },
    edit(rule) {
      this.type = "mod";
      this.$refs.edit.open(rule);
    },
    del(rule) {
      this.$confirm(`确认要删除该设置吗？`, "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            system_set_id: rule.system_set_id,
            type: "del",
          };
          fetchSetting(data)
            .then(() => {
              this.$message.success("操作成功");
              this.getSettingList();
            })
            .catch(() => {});
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  margin-bottom: 20px;
}
.table-expand {
  font-size: 0;
}
.table-expand label {
  width: 90px;
  color: #99a9bf;
}
.table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  .pagination ::v-deep {
    margin-top: 20px;
    text-align: right;
    .el-input__inner {
      text-align: center;
    }
  }
}
</style>
