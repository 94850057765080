<template>
  <div class="goods-container">
    <el-form :inline="true" size="small" class="search-box" :model="searchForm">
      <el-form-item label="商品ID/名称：">
        <el-input
          v-model="searchForm.word"
          placeholder="请输入商品ID/名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="商品组合：">
        <el-select v-model="searchForm.group_name" clearable>
          <el-option
            v-for="(v, i) in groupList"
            :key="i"
            :label="v.group_name"
            :value="v.group_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品分类：">
        <el-select v-model="searchForm.category_id" clearable>
          <el-option
            v-for="(v, i) in categoryList"
            :key="i"
            :label="v.category_name"
            :value="v.category_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品类型：">
        <el-select v-model="searchForm.type" placeholder="请选择商品类型">
          <el-option label="全部" value=""></el-option>
          <el-option label="普通商品" :value="1"></el-option>
          <el-option label="私域商品" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button type="primary" @click="addGoods">添加商品</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="list"
      header-row-class-name="tableHeader"
      style="width: 100%"
      :header-cell-style="{
        background: 'var(--bg-5)',
        color: 'var(--color, #333333)',
      }"
    >
      <el-table-column
        align="center"
        label="商品ID"
        prop="goods_id"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="商品名称"
        prop="name"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="存量"
        prop="amount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="商品组合"
        prop="group_name"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.group_name || "-" }}</span>
        </template>
      </el-table-column>
       <el-table-column
        align="center"
        label="商品分类"
        prop="category_name"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.cates && scope.row.cates.length ? scope.row.cates[0].category_name : "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="组内缩写标签"
        prop="abbr"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.abbr" size="small" type="info">{{
            scope.row.abbr
          }}</el-tag>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商品类型" prop="type">
        <template slot-scope="scope">
          <span>{{ scope.row.type == 1 ? "普通商品" : "私域商品" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        width="300"
        prop="code"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="editGoods(scope.row)">编辑</el-button>
          <el-button type="text" @click="addOperate(scope.row)"
            >运营管理</el-button
          >
          <el-button type="text" @click="editGoodsExtra(scope.row)"
            >附加信息</el-button
          >
          <el-button type="text" @click="editGoodsPic(scope.row)"
            >图片管理</el-button
          >
          <el-button type="text" :load="copyLoading" @click="copyGoods(scope.row)">复制</el-button>
          <el-button
            type="text"
            style="color: #f56c6c"
            @click="delGoods(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
        class="pagination"
        background
        layout="total, slot, prev, pager, next"
        :current-page="searchForm.pageNum"
        :page-size="searchForm.pageSize"
        :total="searchForm.total"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <!-- 添加/编辑商品 -->
    <EditGoods
      ref="editGoods"
      :type="type"
      :item="curItem"
      @refresh="getGoodsList"
    />
    <!-- 商品运营 -->
    <EditGoodsOperate ref="editGoodsOperate" />
    <!-- 商品附加信息 -->
    <EditGoodsPicExtra ref="editGoodsExtra" @refresh="getGoodsList" />
    <!-- 商品图片管理 -->
    <EditGoodsPic ref="editGoodsPic" @refresh="getGoodsList" />
  </div>
</template>

<script>
import { fetchGoodsList, fetchEditGoods, fetchCategoryList, fetchGroupList, fetchCopyGoods } from "@/api/goods";
import EditGoods from "../components/edit.vue";
import EditGoodsPic from "../components/editPic.vue";
import EditGoodsPicExtra from "../components/editPicExtra.vue";
import EditGoodsOperate from "../../marketing/components/edit.vue";

export default {
  data() {
    return {
      loading: false,
      copyLoading: false,
      list: [],
      groupList: [],
      categoryList: [],
      type: "",
      curItem: null,
      searchForm: {
        word: "",
        group_name: "",
        category_id: "",
        type: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  components: {
    EditGoods,
    EditGoodsOperate,
    EditGoodsPic,
    EditGoodsPicExtra,
  },
  created() {
    this.getGroupList()
    this.getCategoryList()
    this.getGoodsList();
  },
  methods: {
    search() {
      this.searchForm.pageNum = 1;
      this.getGoodsList();
    },
    clearSearch() {
      this.searchForm = {
        group_name: "",
        category_id: "",
        type: "",
        word: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      };
      this.getGoodsList();
    },
    getGroupList() {
      this.loading = true
      fetchGroupList().then((res) => {
        const list = res.data || [];
        this.groupList = list;
      })
    },
    getCategoryList() {
      this.loading = true
      fetchCategoryList().then((res) => {
        const list = res.data || [];
        this.categoryList = list;
      })
    },
    getGoodsList() {
      this.loading = true;
      const { pageNum, pageSize, type, group_name, category_id, word } = this.searchForm;
      const data = {
        index: pageNum - 1,
        size: pageSize,
        type,
        group_name,
        category_id,
        word,
      };
      fetchGoodsList(data)
        .then((res) => {
          const list = res.data.list || [];
          this.searchForm.total = res.data.total;
          this.list = list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addGoods() {
      this.type = "add";
      this.$refs.editGoods.open();
    },
    editGoods(goods) {
      this.type = "mod";
      this.$refs.editGoods.open(goods);
    },
    delGoods(goods) {
      this.$confirm(`确认要删除【${goods.name}】吗？`, "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            goods_id: goods.goods_id,
            edit_type: "del",
          };
          this.updateGoods(data);
        })
        .catch(() => {
          return false;
        });
    },
    updateGoods(data) {
      fetchEditGoods(data)
        .then(() => {
          this.$message.success("操作成功");
          this.search();
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    editGoodsPic(goods) {
      this.$refs.editGoodsPic.open(goods);
    },
    editGoodsExtra(goods) {
      this.$refs.editGoodsExtra.open(goods);
    },
    copyGoods(goods) {
      if (this.copyLoading) return
      this.copyLoading = true
      fetchCopyGoods({
        goods_id: goods.goods_id
      }).then(() => {
        this.searchForm.pageNum = 1
        this.getGoodsList()
        this.$message.success('操作成功')
      }).catch(err => {
        this.$message.error(err.errmsg);
      }).finally(() => {
        this.copyLoading = false
      })
    },
    // 页码变更
    handlePageChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getGoodsList();
    },
    addOperate(goods) {
      this.$refs.editGoodsOperate.open({ type: "add", goods });
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-container {
  background: #fff;
  padding: 30px;

  .search-box {
    margin-bottom: 20px;
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .pagination ::v-deep {
      margin-top: 20px;
      text-align: right;
      .el-input__inner {
        text-align: center;
      }
    }
  }
}
</style>
