<template>
  <div class="category-container">
    <div class="search-box">
      <el-button
        v-if="categoryList && categoryList.length < 30"
        class="search-item"
        type="primary"
        @click="addGroup"
        >新增分类</el-button
      >
    </div>
    <el-table
      v-loading="loading"
      :data="categoryList"
      header-row-class-name="tableHeader"
      style="width: 100%"
      :header-cell-style="{
        background: 'var(--bg-5)',
        color: 'var(--color, #333333)',
      }"
    >
      <el-table-column
        align="center"
        label="分类ID"
        prop="category_id"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="分类名称"
        prop="category_name"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createAt"
      ></el-table-column>
      <el-table-column
        align="center"
        label="更新时间"
        prop="updateAt"
      ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        width="200"
        prop="code"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="editCategory(scope.row)">编辑</el-button>
          <el-button type="text" style="color: #F56C6C;" @click="delGroup(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="500px" :title="dialogTitle">
      <div class="group-item">
        <div class="group-title required">分类名称</div>
        <el-input
          v-model="cateName"
          clearable
          size="mini"
          placeholder="请输入"
          show-word-limit
        ></el-input>
      </div>
      <div class="group-item">
        <div class="group-title">去背景图</div>
        <upload-picture
          :limit="1"
          :list="pictures"
          id="main"
          @success="onSuccess($event)"
          @remove="onRemove($event)"
        ></upload-picture>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchUpdateCategory, fetchCategoryList } from "@/api/goods";
import UploadPicture from "@/components/upload-picture/index.vue";

export default {
  data() {
    return {
      loading: false,
      categoryList: [],
      dialogVisible: false,
      cateName: "",
      cateId: "",
      pictures: [],
    };
  },
  components: {
    UploadPicture,
  },
  computed: {
    dialogTitle() {
      return this.cateId ? "编辑分类" : "新建分类";
    },
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    getCategoryList() {
      this.loading = true
      fetchCategoryList().then((res) => {
        const list = res.data || [];
        list.forEach((item) => {
          item.createAt = new Date(
            item.create_time * 1000
          ).toLocaleString();
          item.updateAt = new Date(
            item.update_time * 1000
          ).toLocaleString();
        });
        this.categoryList = list;
      }).finally(() => {
        this.loading = false
      });
    },
    addGroup() {
      this.cateId = "";
      this.cateName = "";
      this.pictures = []
      this.dialogVisible = true;
    },
    editCategory(category){
      this.cateId = category.category_id
      this.cateName = category.category_name
      if (category.removebg) {
        this.pictures = [{
          resource_id: category.removebg,
          url: category.removebg_url
        }]
      }
      this.dialogVisible = true;
    },
    delGroup(group) {
      this.$confirm(
        `确认要删除【${group.category_name}】分类吗？`,
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        const data = {
          category_id: group.category_id,
          type: "del",
        };
        this.updateCategory(data)
      })
      .catch(() => {
        return false
      })
    },
    confirm() {
      const data = {
        category_name: this.cateName,
        type: "add",
      };
      if (this.pictures && this.pictures.length) {
        data.removebg = this.pictures[0].resource_id
      } 
      if (this.cateId) {
        data.category_id = this.cateId
        data.type = "mod"
      }
      this.updateCategory(data);
    },
    updateCategory(data) {
      fetchUpdateCategory(data)
        .then(() => {
          this.cateName = "";
          this.cateId = "";
          this.dialogVisible = false;
          this.$message.success('操作成功')
          this.getCategoryList();
        })
        .catch(() => {
          this.cateName = "";
          this.cateId = "";
          this.dialogVisible = false;
        });
    },
    onSuccess({ file }) {
      const pictures = [{
        resource_id: file.response.key,
        url: file.url
      }];
      this.pictures = pictures
    },
    onRemove() {
      this.pictures = []
    },
  },
};
</script>

<style lang="scss" scoped>
.category-container {
  background: #fff;
  padding: 30px;

  .search-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .group-item {
    margin-bottom: 10px;
  }
}
</style>
