<template>
  <div class="user-container">
    <el-form :inline="true" size="small" class="search-box" :model="searchForm">
      <el-form-item label="用户ID/会员码：">
        <el-input
          v-model="searchForm.word"
          placeholder="请输入用户ID/会员码"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="用户类型：">
        <el-select v-model="searchForm.type" placeholder="请选择用户类型">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="(v, i) in userTypeList"
            :key="i"
            :label="v.label"
            :value="v.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }"
      :data="list"
      style="width: 100%"
    >
      <el-table-column
        align="center"
        label="用户ID"
        width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.user_id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="vipno"
        label="会员码"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="inviter"
        label="邀请者ID"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.inviter || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="paid"
        label="已支付"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.paid ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="type"
        label="类型"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ userType[scope.row.type] || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createAt"
      ></el-table-column>
      <el-table-column
        align="center"
        label="更新时间"
        prop="updateAt"
      ></el-table-column>
      <el-table-column v-if="user.staff_type == 'operation'" label="操作" align="center" fixed="right" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
        class="pagination"
        background
        layout="total, slot, prev, pager, next"
        :current-page="searchForm.pageNum"
        :page-size="searchForm.pageSize"
        :total="searchForm.total"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <!-- 添加用户 -->
    <EditUser
      ref="edit"
      :item="curItem"
      @refresh="getUserList"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchUserList } from "@/api/account";
import EditUser from "../components/edit.vue";
import { UserType, UserTypeList } from "@/utils/role";

export default {
  data() {
    return {
      isLoading: false,
      list: [],
      total: 0,
      curItem: null,
      userType: UserType,
      userTypeList: UserTypeList,
      searchForm: {
        word: "",
        type: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  components: {
    EditUser
  },
  created() {
    this.getUserList();
  },
  methods: {
    search() {
      this.searchForm.pageNum = 1
      this.getUserList()
    },
    clearSearch() {
      this.searchForm = {
        word: "",
        type: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      }
      this.getUserList()
    },
    getUserList() {
      const data = {
        index: this.searchForm.pageNum - 1,
        size: this.searchForm.pageSize,
      };
      if (this.searchForm.word) {
        data.word = this.searchForm.word
      }
      if (this.searchForm.type) {
        data.type = this.searchForm.type
      }
      fetchUserList(data).then((res) => {
        const list = res.data.list || [];
        list.forEach((item) => {
          item.createAt = new Date(
            item.create_time * 1000
          ).toLocaleString();
          item.updateAt = new Date(
            item.update_time * 1000
          ).toLocaleString();
        });
        this.searchForm.total = res.data.total;
        this.list = list;
      }).catch(() => {})
    },
    // 页码变更
    handlePageChange(pageNum) {
      this.searchForm.pageNum = pageNum
      this.getUserList()
    },
    edit(user) {
      this.curItem = user;
      this.$refs.edit.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-container {
  background: #fff;
  padding: 30px;

  .btns {
    margin-bottom: 20px;
  }
  .btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .search-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .el-form-item {
      margin-right: 20px;
    }

    .cascader-behind-item {
      margin-right: 10px;
    }

    .el-input-number {
      .el-input {
        width: 100%;
      }
    }

    .el-input {
      width: 200px;
    }

    .span-margin {
      margin: 0 5px;
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .pagination ::v-deep {
      margin-top: 20px;
      text-align: right;
      .el-input__inner {
        text-align: center;
      }
    }
  }
}
</style>
