<template>
  <div
    class="console-sub-menu"
    :style="{
      'marginLeft': showMainMenu ? '60px' : '0',
      'box-shadow': fourthMenu.length ? '0 0 0 rgba(0, 0, 0, 0.1)' : '',
    }"
  >
    <div class="console-menu-sider">
      <div class="menu-title">{{ selectFirstMenu.meta.title }}</div>

      <el-menu :default-active="activePath || selectSecondRoute" :router="true">
        <template v-for="item in secondMenu">
          <el-submenu
            v-if="item.children && item.children.length"
            :id="'subMenu__' + item.path"
            :key="item.path"
            :index="item.path"
          >
            <template slot="title">
              <div>
                <span>{{ item.meta.title }}</span>
              </div>
            </template>

            <el-menu-item
              v-for="child in item.children"
              :id="'thirdMenu__' + child.path"
              :key="child.path"
              :index="child.path"
              :route="child"
            >
              <span>{{ child.meta.title }}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            :id="'subMenu__' + item.path"
            :key="'menuItem' + item.path"
            :index="item.path"
            :route="item"
          >
            <a>
              <span>{{ item.meta.title }}</span>
            </a>
          </el-menu-item>
        </template>
      </el-menu>
    </div>

    <div v-if="!(showSubMenu && showMainMenu)" class="menu-state-icon" :class="{ active: !showMainMenu }">
      <i class="sealicon sealshousuodaohang" @click.stop="openMainMenu"></i>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'LeftNav',
  props: {
    selectFirstMenu: {
      type: null,
      default: null,
    },
    showMainMenu: {
      type: Boolean,
      default: false,
    },
    showSubMenu: {
      type: Boolean,
      default: false,
    },
    fourthMenu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rootSubmenuKeys: [],
      openKeys: [],
      selectedKeys: [],
      activePath: '',
      isShow: true,
    }
  },
  computed: {
    ...mapGetters(['user']),

    // 接口返回的菜单数组
    menuList() {
      const routers = this.$router.getRoutes() || []
      return routers.map(v => v.path)
    },
    currentRoute() {
      return this.$store.getters.currentRoute
    },
    selectSecondRoute() {
      return this.$store.getters.selectSecondRoute
    },
    secondMenu() {
      let secondMenu = JSON.parse(JSON.stringify(this.selectFirstMenu.children))
      // 飞书登录
      return this.setSecondMenu(secondMenu || [])
    },
    // 当前二级路由
    secondRoute() {
      return this.$store.getters.secondRoute
    },
  },
  watch: {
    secondRoute: {
      handler(val) {
        val && (this.openKeys = [`${this.secondRoute}`])
      },
      immediate: true,
    },
    selectFirstMenu: {
      handler(list) {
        if (list && list.length) {
          const rootMenu = []
          list.children.forEach(item => {
            if (item.children && item.children.length) {
              rootMenu.push(item.path)
            }
          })
          this.rootSubmenuKeys = rootMenu
        }
      },
      immediate: true,
    },
  },
  methods: {
    setSecondMenu(data) {
      let newData = data.filter(item => {
        let isHidden = false
        if (item.children && item.children.length > 0) {
          item.children = item.children.filter(val => {
            return this.menuList.includes(val.path.replace('/*', ''))
          })
          isHidden = item.children.length > 0
        } else {
          isHidden = this.menuList.includes(item.path.replace('/*', ''))
        }

        return isHidden
      })

      return newData
    },
    openMainMenu() {
      this.isShow = false
      this.$emit('mainMenuStateChange', true)
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    // 非门店账号点击菜单打开概述页
    subTitleClick({ key }) {
      const permission = this.$store.getters['account/permission']
      if (permission.type === 3) return
      // 概述页地址集合
      const overviewPages = [
        '/operateCenter/memberCenter',
        '/operateCenter/scrmCenter',
        '/operateCenter/couponCenter',
        '/businessCenter/orders',
        '/businessCenter/goodsCenter',
        '/businessCenter/distribution',
      ]
      const page = overviewPages.find(item => item === key)
      if (page) {
        const canPush = this.$route.path !== page
        if (canPush) {
          this.$router.push(page)
        }
      } else {
        const curRoute = this.secondMenu.find(v => v.path === key)
        this.$router.push(curRoute.children[0].path)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.console-sub-menu {
  position: relative;
  width: 140px;
  background: transparent;
  transition: all 0.2s;
  user-select: none;

  .menu-title {
    margin: 33px 20px;
    color: #fff;
    font-size: 20px;
    transition: all 0.3s;
  }

  .console-menu-sider {
    position: fixed;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    height: 100vh;
    min-height: 100vh;
    padding-bottom: 50px;
    overflow-y: auto;
    background: var(--console-menu-sider, #1273db);
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .menu-img {
    position: relative;
    top: -10px;
    left: -10px;
    width: 44px;
  }

  .menu-state-icon {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    width: 140px;
    height: 50px;
    &.active {
      background-color: var(--console-menu-sider, #1273db);
    }
    .sealicon {
      margin-left: 24px;
      color: #ffffff60;
      font-size: 20px;
      cursor: pointer;
    }
  }

  a {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-menu {
    width: 110px;
    color: #fff;
    background: var(--console-menu-sider, #1273db);
    border-right: 0;
  }

  ::v-deep .el-menu-item,
  ::v-deep .el-submenu__title {
    min-width: auto;
    height: 40px;
    margin-top: 4px;
    margin-bottom: 8px;
    padding-left: 10px !important;
    color: rgba(255, 255, 255, 0.65);
    line-height: 40px;
    border-radius: 5px;
    transition: none;
    &.is-active {
      color: var(--theme, #137dee);
      font-weight: 700;
      background: var(--menu-item-selected-bg, #fff) !important;
      opacity: 1;
    }
    &:not(.is-active):hover {
      opacity: 0.8;
      a {
        color: inherit;
      }
    }
  }

  ::v-deep .el-menu-item:focus,
  ::v-deep .el-menu-item:hover,
  ::v-deep .el-submenu__title:hover {
    background-color: transparent;
  }

  ::v-deep .el-submenu__title {
    margin-bottom: 0;
  }

  ::v-deep .el-menu-item i,
  ::v-deep .el-submenu__title i,
  ::v-deep .el-menu-item a {
    margin-right: 10px;
    color: inherit;
  }

  ::v-deep .el-menu-item a {
    position: relative;
  }

  ::v-deep .el-submenu .el-menu--inline .el-menu-item {
    padding-right: 0 !important;
    padding-left: 20px !important;
    text-align: left;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ::v-deep .el-submenu__title .el-submenu__icon-arrow {
    right: 5px;
    margin-top: -5px;
    margin-right: auto;
  }

  ::v-deep .el-submenu {
    margin-top: 4px;
    margin-bottom: 8px;
    &.is-opened {
      .el-submenu__title {
        margin-bottom: 0;
        color: #fff;
        font-weight: 700;
        opacity: 1;
        .el-submenu__icon-arrow {
          color: inherit;
          font-weight: inherit;
        }
      }
    }
  }
}
</style>
