import fetch from '@/utils/fetch'

/**
 * 查询订单列表
 */
export function fetchOrderList(params) {
  return fetch({
    url: '/admin/deal/list',
    method: 'GET',
    params,
  })
}
