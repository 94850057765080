const { hostname } = window.location
const domain = hostname.slice(0, hostname.indexOf('.'))
const baseURL = '/api'
const envs = {
  'test': 'test',
  'www': 'master',
}
const env = envs[domain] || 'master'

export { baseURL, env }
