<template>
  <div class="upload-container">
    <ul :id="id" class="el-upload-list el-upload-list--picture-card">
      <li
        v-for="item in list"
        :data-id="item.id"
        :key="item.id"
        class="el-upload-list__item is-success"
      >
        <img
          class="el-upload-list__item-thumbnail"
          :src="item.url"
          alt=""
        />
        <span class="el-upload-list__item-actions">
          <span
            v-if="sortable"
            class="el-upload-list__item-delete"
          >
            <i class="el-icon-sort sealkjj-ydwz"></i>
          </span>
          <span
            class="el-upload-list__item-delete"
            @click="handleRemove(item)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </li>
    </ul>
    <el-upload
      :class="{ hide: list.length >= limit }"
      :action="qiniuUrl"
      :data="extra"
      :file-list="list"
      :before-upload="beforeUpload"
      :on-success="onSuccess"
      :list-type="listType"
      :accept="accept"
      :multiple="multiple"
      :show-file-list="false"
      :limit="limit"
    >
      <template v-slot:default>
        <i class="el-icon-plus"></i>
      </template>
      <!-- <div slot="file" slot-scope="{file}"></div> -->
    </el-upload>
  </div>
</template>

<script>
import { qiniuUrl, getUploadToken } from "@/utils/qiniu";
import Sortable from "sortablejs";

export default {
  name: "UploadPicture",
  props: {
    id: {
      type: String,
      default: "sortable",
    },
    list: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: ".jpg,.png,.jpeg,.webp",
    },
    listType: {
      type: String,
      default: "picture-card",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      qiniuUrl,
      // 上传额外信息
      extra: {},
    };
  },
  created() {
    this.initSortable();
  },
  methods: {
    // 初始化拖拽
    initSortable() {
      this.$nextTick(() => {
        const el = document.getElementById(this.id);
        let sortable = Sortable.create(el, {
          animation: 150,
          handle: ".sealkjj-ydwz",
          onEnd: () => {
            const sorts = sortable.toArray();
            this.$emit("sort", { sorts });
          },
        });
      });
    },
    beforeUpload() {
      return new Promise((resolve, reject) => {
        getUploadToken()
          .then((res) => {
            this.extra = res;
            resolve(true);
          })
          .catch(() => {
            this.$message.error("请求七牛token出错,请重试!");
            reject(false);
          });
      });
    },
    onSuccess(response, file, fileList) {
      this.$emit("success", { response, file, fileList });
    },
    handleRemove(file) {
      this.$emit("remove", { file });
    },
    // 打开弹窗
    open(goods) {
      this.dialogVisible = true;
      const contents = goods.contents || [];
      let fileList = [];
      contents.forEach((item) => {
        fileList.push({
          url: item.url,
          response: {
            key: item.resource_id,
          },
        });
      });
      this.fileList = fileList;
      this.$nextTick(() => {
        this.form = goods;
      });
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .hide {
  .el-upload {
    display: none;
  }
}

.upload-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
