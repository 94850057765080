<template>
  <div class="console-third-menu" :style="{ marginLeft: showMainMenu ? '200px' : '140px' }" width="100" :trigger="null">
    <!-- 二级菜单 -->
    <div class="console-submenu-wrapper">
      <template v-for="(item, index) in fourthMenu">
        <a
          :key="index"
          class="submenu-item"
          :class="currentRoute.includes(item.path) ? 'active-menu' : ''"
          @click="changeMenu(item)"
        >
          <span class="item-name">{{ item.meta.title }}</span>
        </a>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SubNav',
  props: {
    showMainMenu: {
      type: Boolean,
      default: false,
    },
    fourthMenu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentRoute() {
      const depth = 2
      const result = []
      const createComb = arr => {
        const leng = arr.length
        if (leng > depth) {
          result.push(arr.join('/'))
          arr.length -= 1
          createComb(arr)
        }
      }
      createComb(this.$store.getters.currentRoute.split('/'))
      return result
    },
  },
  methods: {
    changeMenu(data) {
      this.$router.push(data.path)
    },
  },
}
</script>

<style lang="scss" scoped>
.console-third-menu {
  position: relative;
  z-index: 1000;
  width: 100px;
  height: 100vh;
  background: transparent;
  transition: all 0.2s;
}
.console-submenu-wrapper {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100vh;
  min-height: 100vh;
  padding: 40px 15px;
  overflow: hidden;
  overflow-y: auto;
  background-color: var(--bg-1, #ffffff);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  &::-webkit-scrollbar {
    display: none;
  }
  .submenu-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    cursor: pointer;
    .item-name {
      color: var(--color, #333333);
      transition: all 0.3s;
    }
    &:hover {
      .item-name {
        font-weight: 600;
      }
    }
    &.active-menu {
      .item-name {
        font-weight: 700;
      }
    }
  }

  .menu-img {
    position: relative;
    top: -10px;
    left: -10px;
    width: 44px;
  }
}
</style>
