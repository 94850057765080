import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/normalize.css'
import '@/styles/global.scss'
import { initRouter } from './router'
import guards from '@/router/guards'
import store from './store'
import { loadGuards, loadRoutes, setAppOptions } from '@/utils/routerUtil'
import md5 from 'js-md5'
import Cookies from 'js-cookie'

window.Cookies = Cookies
Vue.prototype.$md5 = md5
const router = initRouter()
setAppOptions({ router, store })
loadRoutes()
loadGuards(guards, { router, store, message: Vue.prototype.$message })
Vue.config.productionTip = false
Vue.use(ElementUI, { gateway: 'web', size: 'small' })
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
